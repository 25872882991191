import React from 'react';

const VerifyAadhar = () => {
    return (
        <div>
            <h1>Verify Aadhar page</h1>
        </div>
    );
};

export default VerifyAadhar;