import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import {
  getV_Balance,
  getV_Balance_Total,
} from "../../store/actions/onvBalanceDataResultAction";
import UpdateBalance from "../../components/update-balance-form";
import RingLoadingHud from "../../components/loadinghud";

const Vreport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [virtualBalance, setVirtualBalance] = useState([]);
  const [virtualBalanceTotal, setVirtualBalanceTotal] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [showProductForm, setShowProductForm] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getVirtualBalance(10, 0);
    getVirtualBalanceTotal();
    console.log("v b page");
  }, []);

  const getVirtualBalance = async (limit, offset) => {
    setIsStoring(true);
    await dispatch(
      getV_Balance({ limit: limit, offset: offset }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setVirtualBalance(response.result);
      }
    });
  };

  const getVirtualBalanceTotal = async () => {
    await dispatch(getV_Balance_Total(dispatch)).then((response) => {
      if (response.result) {
        setVirtualBalanceTotal(response.result);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getVirtualBalance(10, (pageNumber*10)-10);
  };

  const validatePagination = () => {
    if(activePage <= 1) {
      if(virtualBalance.length > 9) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  return (
    <>
      {showProductForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-8">
              <UpdateBalance
                onClose={() => {
                  setShowProductForm(false);
                  getVirtualBalance(10, 0);
                  getVirtualBalanceTotal();
                }}
              />
            </div>
          </div>
        </div>
      )}
      { isStoring && (
        <RingLoadingHud />
      )}
      <div className="container pt-2">
        <div className="row justify-content-around tble">
          <div className="col-lg-6 col-12">
            <p className="">Virtual Balance</p>
       
            <h6 className="pageHeading">Available Balance - {virtualBalanceTotal.amount} INR </h6>
          </div>
          <div className="mr-auto col-lg-6 col-12 text-end">
            <button
              type="button"
              className="mr-auto btn btn-primary"
              onClick={() => setShowProductForm(true)}
            >
              + Update Balance
            </button>
            {/* <button type="button" className="btn btn-white-bg">Update</button> */}
          </div>
        </div>
        <div className="row m-2 mb-2">
                  <div className="col-md-12 card-1 ovrscrl">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                <th scope="col">S. No.</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Added By</th>
                  <th scope="col">Vendor</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              {virtualBalance && virtualBalance.length > 0 && (
                <tbody>
                  {virtualBalance.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <th className="align-middle">
                        <p>{i+1+((activePage*10) - 10)}</p>
                        </th>
                        <td className="align-middle">
                          <p>
                            {item.amount}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.adminName}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.vendorName}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.comments}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.type}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.status}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p class="warning-badge">
                            {getDateFormat(item.created_at)}
                          </p>
                        </td>
                        {/* <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => {
                              navigate(`/order-detail?orderId=${od.id}`);
                            }}
                          >
                            <AiFillEye />
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {
              validatePagination()
                ? <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={50}
                  pageRangeDisplayed={5}
                  onChange={(number) => handlePageChange(number)}
                /> : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Vreport;
