import React, { Component, useEffect, useRef, useState } from "react";
// import { Grid, Card, Icon, Image, Button } from 'semantic-ui-react'
import "./dashboard.css";
import Card from "./card/card";
import Charts from "./chart/chart";
import ECharts from "./chart/echart";
import InfoCard from "./card/infocard";
import PlanCard from "./card/plancard";
import CIcon from "@coreui/icons-react";
import { cilArrowBottom, cilArrowTop, cilOptions } from "@coreui/icons";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";
import {
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CWidgetStatsA,
} from "@coreui/react";
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core";

import { FaCircle } from "react-icons/fa";
import Avatar1 from "../../assets/dashboard/images/user/avatar-1.jpg";
import Avatar2 from "../../assets/dashboard/images/user/avatar-2.jpg";
import Avatar3 from "../../assets/dashboard/images/user/avatar-3.jpg";
import Avatar4 from "../../assets/dashboard/images/user/avatar-4.jpg";
import Avatar5 from "../../assets/dashboard/images/user/avatar-5.jpg";
import circle from "../../assets/dashboard/circle.svg";
import { useDispatch } from "react-redux";
import {
  getUpdateVirtualWalletBalanceRequest,
  getVirtualWalletListRequest,
} from "../../store/actions/onvBalanceDataResultAction";
import moment from "moment-timezone";
import RingLoadingHud from "../../components/loadinghud";
import AlertView from "../../components/alert-view";
import { getDashboardDetails } from "./dashboardrequest";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const [updateBanalceRequestList, setUpdateBanalceRequestList] = useState([]);
  const [isStoring, setIsStoring] = useState(false);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    getWalletTransactionsList(5, 0);
    getDashBoardData();
    console.log("Admin dashboard");
  }, []);

  const getDashBoardData = async () => {
    // setIsStoring(true);
    await dispatch(
      getDashboardDetails(
        {
          id: "",
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        console.log(response);
        setDashboardData(response.result);
      } else {
        setDashboardData({});
        // showAlert(response.message);
      }
    });
  };

  const getRespondUpdateBalanceRequest = async (status, vID, amount, rId) => {
    setIsStoring(true);
    await dispatch(
      getUpdateVirtualWalletBalanceRequest(
        {
          vendorId: vID,
          walletAmount: amount,
          comments: "OK",
          adminId: localStorage.getItem("userId"),
          request_status: status,
          request_id: rId,
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        getWalletTransactionsList(5, 0);
      } else {
        showAlert(response.message);
      }
    });
  };

  const getWalletTransactionsList = async (limit, offset) => {
    // setIsStoring(true);
    await dispatch(
      getVirtualWalletListRequest(
        {
          limit: limit,
          offset: offset,
          status: "PENDING",
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        setUpdateBanalceRequestList(response.result);
      } else {
        setUpdateBanalceRequestList([]);
        // showAlert(response.message);
      }
    });
  };

  const getDateFormat = (date) => {
    // console.log(date);
    // // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    moment.tz.setDefault('"Asia/Calcutta"');
    let currentDate = moment(date).format("DD MMM YY, hh:mm a");
    return currentDate;
  };

  const manageUpdateBalanceStatus = (item, status) => {
    getRespondUpdateBalanceRequest(status, item.user_id, item.amount, item.id);
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          style={{ width: 0 }}
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      <div className="container pt-2">
        <div className="formcontainer" id="transfer">
          <div>
            <h3 className="tble">Dashboard</h3>
          </div>
          <div class="row mb-4">
            <div class="col-lg-2 col-sm-4 col-6 ng-star-inserted">
              <mat-card class="mat-mdc-card mdc-card bg-light-primary text-center">
                <mat-card-content class="mat-mdc-card-content p-32">
                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-user-male.svg" />
                  <h4 class="f-w-600 f-14 m-t-8 mat-subtitle-2 text-primary">Retailers</h4>
                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-primary">96</h6>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-2 col-sm-4 col-6 ng-star-inserted">
              <mat-card class="mat-mdc-card mdc-card bg-light-warning text-center">
                <mat-card-content class="mat-mdc-card-content p-32">
                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-briefcase.svg" />
                  <h4 class="f-w-600 f-14 m-t-8 mat-subtitle-2 text-warning">Recharge</h4>
                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-warning">₹ {dashboardData.total?.recharge?.total ?? "0"}</h6>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-2 col-sm-4 col-6 ng-star-inserted">
              <mat-card class="mat-mdc-card mdc-card bg-light-accent text-center">
                <mat-card-content class="mat-mdc-card-content p-32">
                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-mailbox.svg" />
                  <h4 class="f-w-600 f-14 m-t-8 mat-subtitle-2 text-accent">BBP</h4>
                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-accent"> ₹ {dashboardData.total?.bbps?.total ?? "0"}</h6>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="col-lg-2 col-sm-4 col-6 ng-star-inserted">
              <mat-card class="mat-mdc-card mdc-card bg-light-success text-center">
                <mat-card-content class="mat-mdc-card-content p-32">
                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-speech-bubble.svg" />
                  <h4 class="f-w-600 f-14 m-t-8 mat-subtitle-2 text-success">Pay In</h4>
                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-18 text-success">₹ {dashboardData.total?.payIn?.total ?? "0"}</h6>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-2 col-sm-4 col-6 ng-star-inserted">
              <mat-card class="mat-mdc-card mdc-card bg-light-error text-center">
                <mat-card-content class="mat-mdc-card-content p-32">
                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-favorites.svg" />
                  <h4 class="f-w-600 f-14 m-t-8 mat-subtitle-2 text-error">Pay Out</h4>
                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-error">₹ {dashboardData.total?.payout?.total ?? "0"}</h6>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="col-lg-2 col-sm-4 col-6 ng-star-inserted">
              <mat-card class="mat-mdc-card mdc-card bg-light-accent text-center">
                <mat-card-content class="mat-mdc-card-content p-32">
                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-connect.svg" />
                  <h4 class="f-w-600 f-14 m-t-8 mat-subtitle-2 text-accent">Reports</h4>
                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-accent">59</h6>
                </mat-card-content>
              </mat-card>
            </div>

          </div>

          <div className="row">


            <div class="col-xl-9 col-md-6">
              <div class="ncard Recent-Users mb-4">
                <div class="ncard-header">
                  <h5>Recent Balance update requests</h5>
                </div>
                <div class="ncard-block px-3 py-3">
                  <div class="table-responsive">
                    <table class="ntable ntable-hover thead-light">
                      <tbody>
                        {updateBanalceRequestList.map((item, i) => {
                          return (
                            <tr className="unread">
                              <td>
                                <img
                                  class="rounded-circle"
                                  style={{ width: "40px" }}
                                  src={Avatar2}
                                  alt="activity-user"
                                />
                              </td>
                              <td>
                                <h6 class="mb-1">{item.userName}</h6>
                                <p class="m-0">{item.comments}</p>
                                <p class="m-0">{item.userPhone}</p>
                              </td>
                              <td>
                                <h6 class="mb-1">₹ {item.amount}</h6>
                              </td>
                              <td>
                                <h6 class="text-muted">
                                  <FaCircle class="text-c-green f-10 m-r-15" />
                                  {getDateFormat(item.created_at)}
                                </h6>
                              </td>
                              <td>
                                <a
                                  href="#!"
                                  class="label theme-bg2 text-white f-12"
                                  onClick={() =>
                                    manageUpdateBalanceStatus(item, "REJECTED")
                                  }
                                >
                                  Reject
                                </a>
                                <a
                                  href="#!"
                                  class="label theme-bg text-white f-12"
                                  onClick={() =>
                                    manageUpdateBalanceStatus(item, "PROCESSED")
                                  }
                                >
                                  Approve
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="ncard Recent-Users mb-4">
                <div class="ncard-header">
                  <h5>Data Statistics</h5>
                </div>
                <div class="ncard-block px-3 py-3">
                  <CWidgetStatsA
                    className="mb-4"
                    color=""
                    value={
                      <>
                        44K{" "}
                        <span className="fs-6 fw-normal">
                          (-23.6% <CIcon icon={cilArrowBottom} height={20} />)
                        </span>
                      </>
                    }
                    title="Sales"
                    chart={
                      <CChartBar
                        className="mt-3 mx-3"
                        style={{ height: "70px" }}
                        data={{
                          labels: [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                            "January",
                            "February",
                            "March",
                            "April",
                          ],
                          datasets: [
                            {
                              label: "My First dataset",
                              backgroundColor: "#1dd6d0",
                              borderColor: "#1c3faa",
                              data: [
                                78, 81, 80, 45, 34, 12, 40, 85, 65, 23, 12, 98, 34,
                                84, 67, 82,
                              ],
                              barPercentage: 0.6,
                            },
                          ],
                        }}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          scales: {
                            x: {
                              grid: {
                                display: false,
                                drawTicks: false,
                              },
                              ticks: {
                                display: false,
                              },
                            },
                            y: {
                              grid: {
                                display: false,
                                drawBorder: false,
                                drawTicks: false,
                              },
                              ticks: {
                                display: false,
                              },
                            },
                          },
                        }}
                      />
                    }
                  />
                </div>
              </div>

            </div>
            <div class="col-xl-3 col-md-6">
              <div class="ncard daily-sales">
                <div class="ncard-block">
                  <h6 class="mb-3 dhead">Daily Sales</h6>
                  <div class="row d-flex align-items-center">
                    <div class="col-9">
                      <h5 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                        <i class="feather icon-arrow-up text-c-green f-15 m-r-10"></i>
                        ₹ {dashboardData.today?.all?.total ?? "0"}
                      </h5>
                    </div>

                    <div class="col-3 text-right">
                      {/* <p class="m-b-0">67%</p> */}
                    </div>
                  </div>
                  <div class="progress m-t-20" style={{ height: "7px" }}>
                    <div
                      class="progress-bar progress-c-theme"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="ncard Monthly-sales">
                <div class="ncard-block">
                  <h6 class="mb-3 dhead">Yesterday Sales</h6>
                  <div class="row d-flex align-items-center">
                    <div class="col-9">
                      <h5 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                        <i class="feather icon-arrow-down text-c-red f-15 m-r-10"></i>
                        ₹ {dashboardData.yesterday?.all?.total ?? "0"}
                      </h5>
                    </div>
                    <div class="col-3 text-right">
                      {/* <p class="m-b-0">36%</p> */}
                    </div>
                  </div>
                  <div class="progress m-t-20" style={{ height: "7px" }}>
                    <div
                      class="progress-bar progress-c-theme2"
                      role="progressbar"
                      style={{ width: "35%" }}
                      aria-valuenow="35"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="ncard yearly-sales">
                <div class="ncard-block">
                  <h6 class="mb-3 dhead">Monthly Sales</h6>
                  <div class="row d-flex align-items-center">
                    <div class="col-9">
                      <h5 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                        <i class="feather icon-arrow-up text-c-green f-15 m-r-10"></i>
                        ₹ {dashboardData.lastMonth?.all?.total ?? "0"}
                      </h5>
                    </div>
                    <div class="col-3 text-right">
                      {/* <p class="m-b-0">80%</p> */}
                    </div>
                  </div>
                  <div class="progress m-t-20" style={{ height: "7px" }}>
                    <div
                      class="progress-bar progress-c-theme"
                      role="progressbar"
                      style={{ width: "70%" }}
                      aria-valuenow="70"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default AdminDashboard;