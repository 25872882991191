import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Toast } from "react-bootstrap";

const AlertView = (props) => {
    const [showMessage, setShowMessage] = useState(props.message);
    useEffect(()=>{
        console.log(props);
    })
    return (
        <div class="container"
            style={{
                position: "fixed",
                height: "100%",
                zIndex: "999",
            }}
        >
            <div className="row">
                <div className="col-md-6"
                    style={{
                        margin: "50px auto",
                    }}>
            <Toast style={ { backgroundColor:"#04A9F5", color:"white"}}
                onClose={() => {
                    console.log(false);
                    props.onClose();
                }}
                show={true}
                delay={3000}
                autohide
            >
                <Toast.Header className="table" closeButton={false}>
                    <strong className="mr-auto">Alert !!</strong>
                </Toast.Header>
                <Toast.Body className="table">{showMessage}</Toast.Body>
            </Toast>
        </div>
        </div>
        </div>
    );
}

export default AlertView;