import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addUserReq,
  getUserInfoReq,
  slabListRequest,
  updateUserReq,
} from "../../store/actions/onUserDataResultAction";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { useLocation } from "react-router-dom";
import AlertView from "../../components/alert-view";
import { data } from "jquery";
import { faL } from "@fortawesome/free-solid-svg-icons";

const NewUser = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userDataState = location.state;
  const [errors, setErrors] = useState({});
  const [slabList, setSlabList] = useState([]);
  const [slab, setSlab] = useState({});
  const [isStoring, setIsStoring] = useState(false);
  const [buttonTitle, setButtonTitle] = useState('Add Retailer');
  const [productAddForm, setProductAddForm] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    pan: "",
    aadhar: "",
    voter: "",
    gst: "",
    latitude: "",
    longitude: "",
    shop_address: "",
    city: "",
    state: "",
    pincode: "",
    slab_id: "",
    dob: "",
    company: "",
  });

  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    getSlabList();
    if (userDataState != null) {
      console.log('...', userDataState)
      getUserInfo();
    }
  }, []);

  const getUserInfo = async () => {
    setIsStoring(true);
    await dispatch(
      getUserInfoReq({ id: userDataState.id }, dispatch)).then(
        (response) => {
          setButtonTitle('Update Retailer');
          setIsStoring(false);
          if (response.result) {
            updateProductForm(response.result)
          } else {
            showAlert(response.message)
          }
        }
      )
  }

  const updateProductForm = (data) => {
    let object = {
      name: data.info.name,
      email: data.info.email,
      phone: data.info.phone,
      password: data.info.phone,
      pan: data.details.pan,
      aadhar: data.details.aadhar,
      voter: data.details.voter,
      gst: data.details.gst,
      latitude: data.details.latitude,
      longitude: data.details.longitude,
      shop_address: data.details.shop_address,
      city: data.details.city,
      state: data.details.state,
      pincode: data.details.pincode,
      slab_id: data.details.slab_id,
      dob: data.details.dob,
      company: data.details.company,
    }
    setProductAddForm(object);
  }

  const getSlabList = async () => {
    setIsStoring(true);
    await dispatch(slabListRequest({}, dispatch)).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setSlabList(response.result);
        setSlab(response.result[0]);
      }
    });
  };
  const handleValidation = () => {
    let fields = productAddForm;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Name field cannot be empty";
      console.log("validation failed 1");
    }

    if (typeof fields["name"] !== "undefined" && !fields["name"] === false) {
      if (!fields["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
        console.log("validation failed 2");
      }
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email field cannot be empty";
      console.log("validation failed 3");
    }

    if (typeof fields["email"] !== "undefined" && !fields["email"] === false) {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = "Phone field cannot be empty";
    }

    if (
      typeof fields["phone"] !== "0000000000" &&
      !fields["phone"] === false &&
      fields["phone"].length < 10
    ) {
      if (!fields["phone"].match(/^[0-9]+$/)) {
        formIsValid = false;
        errors["phone"] = "Only numbers and 10 digits";
      }
    }

    // if (!fields["password"] && !fields["password"].length < 8) {
    //   formIsValid = false;
    //   errors["password"] = " Password field cannot be empty";
    // }
    if (!fields["pan"]) {
      formIsValid = false;
      errors["pan"] = "Pan no. field cannot be empty";
    }
    console.log(fields["pan"].length);
    if (fields["pan"].length < 9) {
      formIsValid = false;
      errors["pan"] = "Pan no. field shoulb be minimum 10 characters";
    }
    if (!fields["aadhar"]) {
      formIsValid = false;
      errors["aadhar"] = "Aadhar field cannot be empty";
    }
    if (!fields["aadhar"].match(/^[0-9]+$/)) {
      formIsValid = false;
      errors["aadhar"] = "Only numbers and 12 digits";
    }
    if (fields["aadhar"].length < 12) {
      formIsValid = false;
      errors["aadhar"] = "Aadhar no. field shoulb be minimum 12 characters";
    }
    if (!fields["voter"]) {
      formIsValid = false;
      errors["voter"] = "Voter field cannot be empty";
    }
    if (!fields["gst"]) {
      formIsValid = false;
      errors["gst"] = "GST field cannot be empty";
    }
    if (fields["gst"].length < 15) {
      formIsValid = false;
      errors["gst"] = "GST no. field shoulb be minimum 15 characters";
    }
    if (!fields["latitude"]) {
      formIsValid = false;
      errors["latitude"] = "Latitude field cannot be empty";
    }
    if (!fields["longitude"]) {
      formIsValid = false;
      errors["longitude"] = "Longitude field cannot be empty";
    }
    if (!fields["shop_address"]) {
      formIsValid = false;
      errors["shop_address"] = "Shop address field cannot be empty";
    }
    if (!fields["city"]) {
      formIsValid = false;
      errors["city"] = "City field cannot be empty";
    }
    if (!fields["state"]) {
      formIsValid = false;
      errors["state"] = "State field cannot be empty";
    }
    if (!fields["pincode"]) {
      formIsValid = false;
      errors["pincode"] = "Pincode field cannot be empty";
    }
    if (!fields["slab_id"]) {
      formIsValid = false;
      errors["slab_id"] = "Slab id field cannot be empty";
    }
    if (!fields["dob"]) {
      formIsValid = false;
      errors["dob"] = "DOB id field cannot be empty";
    }

    setErrors(errors);
    console.log(errors);
    return formIsValid;
  };
  const addUser = (e) => {
    console.log("SDebug > ", productAddForm);
    if (handleValidation()) {
      setIsStoring(true);
      let body = {
        name: productAddForm.name,
        email: productAddForm.email,
        password: productAddForm.phone,
        role_id: "4",
        phone: productAddForm.phone,
        status: "ACTIVE",
        pan: productAddForm.pan,
        aadhar: productAddForm.aadhar,
        voter: productAddForm.voter,
        gst: productAddForm.gst,
        latitude: productAddForm.latitude,
        longitude: productAddForm.longitude,
        shop_address: productAddForm.shop_address,
        city: productAddForm.city,
        state: productAddForm.state,
        pincode: productAddForm.pincode,
        slab_id: productAddForm.slab_id,
        dob: productAddForm.dob,
        company: productAddForm.company,
        superUserId: localStorage.getItem("userId"),
      };

      if (userDataState != null) {
        body["id"] = userDataState.id
        dispatch(updateUserReq(body, dispatch)).then((response) => {
          if (response.status == true) {
            let emprtValues = {
              name: "",
              email: "",
              phone: "",
              password: "",
              pan: "",
              aadhar: "",
              voter: "",
              gst: "",
              latitude: "",
              longitude: "",
              shop_address: "",
              city: "",
              state: "",
              pincode: "",
              slab_id: "",
              dob: "",
              company: "",
            };
            setProductAddForm(emprtValues);
            setIsStoring(false);
            showAlert(response.message)
          } else {
            let userResp = JSON.parse(response.result);
            let message = userResp.email != null ? userResp.email : userResp.phone != null ? userResp.phone : response.message
            setIsStoring(false);
            showAlert(message)
          }

        });
      } else {
        dispatch(addUserReq(body, dispatch)).then((response) => {
          if (response.status == true) {
            let emprtValues = {
              name: "",
              email: "",
              phone: "",
              password: "",
              pan: "",
              aadhar: "",
              voter: "",
              gst: "",
              latitude: "",
              longitude: "",
              shop_address: "",
              city: "",
              state: "",
              pincode: "",
              slab_id: "",
              dob: "",
              company: "",
            };
            setProductAddForm(emprtValues);
            setIsStoring(false);
            showAlert(response.message)
          } else {
            let userResp = JSON.parse(response.result);
            let message = userResp.email != null ? userResp.email : userResp.phone != null ? userResp.phone : response.message
            setIsStoring(false);
            showAlert(message)
          }

        });
      }
    } else {
      console.log("validation failed");
    }
  };

  const onSectionSelect = (sectionId) => { };

  const handleChanges = (e) => {
    setProductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const handleSlabChanges = (e) => {
    setProductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    const selectedSlab = slabList.find((slab) => slab.id === e.target.value);
    setSlab(selectedSlab);
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      {
        isAlertView && (
          <AlertView
            message={alertMessage}
            onClose={() => {
              setIsAlertView(false);
            }}
          />
        )
      }
      <div className="container pt-2">
        <div className="row justify-content-around">
          <div class="tble">
            <p className="">Add Retailer</p>
          </div>
          <div className="formcontainer" id="transfer">
            <div className="row justify-content-left m-3">
                          <div className="row ncard justify-content-left p-3">
                              <div className="col-lg-6 col-12">
                                  <p className="pageHeading f-16 f-w-600 mb-2">Basic Details:</p>
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    refs="name"
                    className="form-control"
                    value={productAddForm.name}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter name"
                    required="required"
                  />
                  <span style={{ color: "red" }}>{errors["name"]}</span>
               
                <div className="">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    className="form-control"
                    value={productAddForm.email}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter email"
                  />
                  <span style={{ color: "red" }}>{errors["email"]}</span>
                </div>
                <div className="">
                  <label>Phone</label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    value={productAddForm.phone}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter phone"
                  />
                  <span style={{ color: "red" }}>{errors["phone"]}</span>
                </div>
                {/* <div className="">
                  <label>Password</label>
                  <input
                    type="text"
                    name="password"
                    className="form-control"
                    value={productAddForm.password}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter password"
                  />
                  <span style={{ color: "red" }}>{errors["password"]}</span>
                </div> */}
                <div className="">
                  <label>DOB</label>
                  <input
                    type="date"
                    name="dob"
                    min="1970-01-01"
                    className="form-control"
                    value={productAddForm.dob}
                    onChange={(e) => handleChanges(e)}
                    placeholder="dd/mm/yyyy"
                  />
                  <span style={{ color: "red" }}>{errors["dob"]}</span>
                                  </div>
                                  <p className="pageHeading f-16 f-w-600 mb-2">Address Details:</p>

                                  <div className="">
                                      <label>Latitude</label>
                                      <input
                                          type="text"
                                          name="latitude"
                                          className="form-control"
                                          value={productAddForm.latitude}
                                          onChange={(e) => handleChanges(e)}
                                          placeholder="Enter latitude"
                                      />
                                      <span style={{ color: "red" }}>{errors["latitude"]}</span>
                                  </div>
                                  <div className="">
                                      <label>Longitude</label>
                                      <input
                                          type="text"
                                          name="longitude"
                                          className="form-control"
                                          value={productAddForm.longitude}
                                          onChange={(e) => handleChanges(e)}
                                          placeholder="Enter longitude"
                                      />
                                      <span style={{ color: "red" }}>{errors["longitude"]}</span>
                                  </div>
                                  <div className="">
                                      <label>Shop Address</label>
                                      <input
                                          type="text"
                                          name="shop_address"
                                          className="form-control"
                                          value={productAddForm.shop_address}
                                          onChange={(e) => handleChanges(e)}
                                          placeholder="Enter shop address"
                                      />
                                      <span style={{ color: "red" }}>{errors["shop_address"]}</span>
                                  </div>
                                  <div className="">
                                      <label>City</label>
                                      <input
                                          type="text"
                                          name="city"
                                          className="form-control"
                                          value={productAddForm.city}
                                          onChange={(e) => handleChanges(e)}
                                          placeholder="Enter City"
                                      />
                                      <span style={{ color: "red" }}>{errors["city"]}</span>
                                  </div>
                                  <div className="">
                                      <label>State</label>
                                      <input
                                          type="text"
                                          name="state"
                                          className="form-control"
                                          value={productAddForm.state}
                                          onChange={(e) => handleChanges(e)}
                                          placeholder="Enter State"
                                      />
                                      <span style={{ color: "red" }}>{errors["state"]}</span>
                                  </div>
                                  <div className="">
                                      <label>Pincode</label>
                                      <input
                                          type="number"
                                          name="pincode"
                                          pattern="[0-9]{5}"
                                          className="form-control"
                                          value={productAddForm.pincode}
                                          onChange={(e) => handleChanges(e)}
                                          placeholder="Enter Pincode"
                                          minLength={6}
                                      />
                                      <span style={{ color: "red" }}>{errors["pincode"]}</span>
                                  </div>
                </div>
                              <div className="col-lg-6 col-12">
                                  <p className="pageHeading f-16 f-w-600 mb-2">Company Details:</p>
                  <label>Pan No</label>
                  <input
                    type="text"
                    name="pan"
                    className="form-control"
                    value={productAddForm.pan}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter pan number"
                  />
                  <span style={{ color: "red" }}>{errors["pan"]}</span>
                
                <div className="">
                  <label>Aadhar No.</label>
                  <input
                    type="text"
                    name="aadhar"
                    className="form-control"
                    value={productAddForm.aadhar}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter aadhar number"
                  />
                  <span style={{ color: "red" }}>{errors["aadhar"]}</span>
                </div>
                <div className="">
                  <label>Voter Id.</label>
                  <input
                    type="text"
                    name="voter"
                    className="form-control"
                    value={productAddForm.voter}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter voter id"
                  />
                  <span style={{ color: "red" }}>{errors["voter"]}</span>
                </div>
                <div className="">
                  <label>Company</label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    value={productAddForm.company}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter company name"
                  />
                  <span style={{ color: "red" }}>{errors["company"]}</span>
                </div>
                <div className="">
                  <label>GST</label>
                  <input
                    type="text"
                    name="gst"
                    className="form-control"
                    value={productAddForm.gst}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter gst no."
                  />
                  <span style={{ color: "red" }}>{errors["gst"]}</span>
                                  </div>
                                  <p className="pageHeading f-16 f-w-600 mb-2">Slab Details:</p>
                                  <div className="">
                                      <label>Slab</label>
                                      <select
                                          name="slab_id"
                                          className="form-control"
                                          onChange={(e) => handleSlabChanges(e)}
                                      >
                                          {slabList.map((e, key) => {
                                              return (
                                                  <option key={key} value={e.id}>
                                                      {e.name}
                                                  </option>
                                              );
                                          })}
                                      </select>
                                      <span style={{ color: "red" }}>{errors["slab_id"]}</span>
                                  </div>
                                  <div className="">
                                      <label>Share %</label>
                                      <br />
                                      <label>Admin: {slab.owner}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label>Partner: {slab.partner}</label>
                                      <br />
                                      <label>Distributor: {slab.distributor}</label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <label>Retailer: {slab.retailer}</label>
                                  </div>
                </div>
                
                

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{ marginTop: "25px" }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => addUser()}
                  >
                    {buttonTitle}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewUser;
