import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#3778C2'
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#3778C2',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
  },
  description: {
    // width: '60%',
    textAlign: 'left',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    // width: '10%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  rate: {
    // width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: 'right',
    paddingRight: 8,
  },
  amount: {
    // width: '15%',
    textAlign: 'right',
    paddingRight: 8,
  },
});

const InvoiceTableRow = ({ item }) => {
  const rows = (
    <View style={styles.row} key={1}>
      <Text style={styles.description}>{item.userName}</Text>
      <Text style={styles.qty}>{item.amount}</Text>
      <Text style={styles.rate}>{item.provider}</Text>
      <Text style={styles.amount}>{getComments(item)}</Text>
      <Text style={styles.amount}>{item.entry_type}</Text>
      <Text style={styles.amount}>{getStatus(item)}</Text>
    </View>
  );

  const getComments = (item) => {
    let resp = JSON.parse(item.transaction_meta);
    let message = "";
    let merch_Details = resp.merchantAdditionalDetails.split("}{");
    if (merch_Details.length > 2) {
      message = merch_Details[1];
    }
    message = message + " Ref No: " + resp.merchantTransactionIdentifier;
    return message;
  };

  const getStatus = (item) => {
    let resp = JSON.parse(item.transaction_meta);
    if (resp.paymentMethod.paymentTransaction.statusMessage == "success") {
      return "Success";
    } else if (resp.response_status_id == 0) {
      return "Rejected";
    }
  };

  return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow;