import React, { useContext, useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import {
  ZFetchBillRequest,
  ZPayBillRequest,
  ZpayRechargeRequest,
  getBbpsOptionsRequest,
  getBbpsProvidersRequest,
  getZpayOperatorsRequest,
} from "../rechargezpay/actions/onZpayBankingResultAction";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { updateReportService } from "../../api/reportsAPI";
import { BalanceContext } from "../../components/walletprovider";
import AlertView from "../../components/alert-view";
import { VirtualBalanceContext } from "../../components/virtualbalanceprovider";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { getVirtualWalletBalance } from "../dashboard/dashboardrequest";

const ZpBbps = () => {
  const dispatch = useDispatch();
  const [rcForm, setRcForm] = useState({
    amount: "",
    account: "",
    operator: "",
    mainBal: "",
    virtualBal: ""
  });
  const [operators, setOPerators] = useState([]);
  const [providers, setProviders] = useState([]);
  const [accountTitle, setAccountTitle] = useState("Account Number");
  const [billInfo, setBillInfo] = useState("");
  const [billInfoError, setBillInfoError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isCorrectBill, setIsCorrectBill] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const state = useContext(BalanceContext);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const vState = useContext(VirtualBalanceContext);
  const [paymentType, setPaymentType] = useState("");

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(getBbpsOptionsRequest({}, dispatch)).then((response) => {
      setIsStoring(false);
      if (response) {
        setOPerators(response.result);
        getBbpsProviders(response.result[0].type);
      }
    });
  };

  const getBbpsProviders = async (optionType) => {
    setIsStoring(true);
    await dispatch(
      getBbpsProvidersRequest({ type: optionType }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response) {
        setProviders(response.result);
        setAccountTitle(response.result[0].param);
      }
    });
  };

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleRadioInputChanges = (e) => {
    console.log(e.target.value);
    setPaymentType(e.target.value);
  };

  const handleOptionChanges = (e) => {
    setBillInfo("");
    setBillInfoError("");
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
    getBbpsProviders(e.target.value);
  };

  const handleProviderChanges = (e) => {
    setBillInfo("");
    setBillInfoError("");
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
    let item = providers.find((element) => {
      return element.code === e.target.value;
    });
    console.log(item);
    setAccountTitle(item.param);
  };

  const updateTransaction = (res, respMessage) => {
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "BBPS",
      page: "BBPS",
      pageOption: "BBPS",
      loginId: localStorage.getItem("userId"),
      superUserId: localStorage.getItem("superuserId"),
      walletType: paymentType,
      status: respMessage
    };

    dispatch(updateReportService(body, dispatch)).then((response) => { 
      getRetaierWalletBalance()
    });
  };

  const getRetaierWalletBalance = async () => {
    await dispatch(
      getVirtualWalletBalance(
        {
          id: localStorage.getItem("userId")
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        state.setAmount(response.result.mainBalance);
        vState.setVirtualAmount(response.result.virtualBalance);
      } 
    });
  }

  const validatePaymentType = () => {
    let walletAmountVal = parseInt(state.amount)
    let walletVirtualAmountVal = parseInt(vState.virtualamount)
    let rcAmount = parseInt(rcForm.amount)
    if(paymentType === 'MAIN_WALLET') {
      return walletAmountVal > rcAmount ? true : false
    } else if(paymentType === 'VIRTUAL_WALLET') {
      return walletVirtualAmountVal > rcAmount ? true : false
    } else {
      return false
    }
  }
  

  const makeTransaction = (e) => {
    
    console.log(validatePaymentType());
    if (rcForm.account.length === 0) {
      showAlert("Please enter account number");
    } else if (rcForm.amount.length === 0) {
      showAlert("Please enter mobile number");
    } else if (paymentType.length === 0) {
      showAlert("Please select wallet type");
    } else if (validatePaymentType() == false) {
      showAlert("Your wallet amount is low. Please recharge");
    } else {
      let body = {
        code: rcForm.operator,
        account: rcForm.account,
        amount: rcForm.amount,
      };

      dispatch(ZPayBillRequest(body, dispatch)).then((response) => {
        console.log(response);
        if (response) {
          if (response.result.status === "error") {
            alert(response.result.error_message);
            updateTransaction(response, "fail");
          } else {
            alert(response.result.message);
            updateTransaction(response, "success");
          }
        } else {
          alert("Please enter all fields and try again");
        }
      });
    }
  };

  const fetchBill = (e) => {
    setBillInfo("");
    setBillInfoError("");
    setLoading(true);
    let body = {
      code: rcForm.operator,
      account: rcForm.account,
    };

    dispatch(ZFetchBillRequest(body, dispatch)).then((response) => {
      console.log(response);
      if (response) {
        setLoading(false);
        if (response.status === "false") {
          alert("Error in retriving Bill");
        } else {
          if (response.result.status == "error") {
            setBillInfoError(response.result.errortext);
          } else {
            setRcForm({ ...rcForm, amount: response.result.billAmount });
            setBillInfo(
              response.result.name + " / " + response.result.billAmount
            );
          }
        }
      } else {
        setLoading(false);
        alert("Please enter all fields and try again");
      }
    });
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          style={{ width: 0 }}
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      <div className="container pt-2">
        <div className="row justify-content-around">
          <div class="tble">
            <p className="">BBPS Pay Bills</p>
          </div>
          <div className="formcontainer" id="transfer">
            <div className="row justify-content-around m-2">
              <div className="col-lg-6 col-12 ncard py-2">
                <label>Service *</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleOptionChanges(e)}
                >
                  {operators.map((e, key) => {
                    return (
                      <option key={key} value={e.type}>
                        {e.type}
                      </option>
                    );
                  })}
                </select>

                <label>Provider *</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleProviderChanges(e)}
                >
                  {providers.map((e, key) => {
                    return (
                      <option key={key} value={e.code}>
                        {e.service}
                      </option>
                    );
                  })}
                </select>

                <label>{accountTitle}</label>
                <input
                  name="account"
                  maxLength="20"
                  className="form-control"
                  value={rcForm.account}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Amount *</label>
                <input
                  name="amount"
                  maxLength="20"
                  type="number"
                  className="form-control"
                  value={rcForm.amount}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Wallet Mode *</label>
                <div onChange={(e) => handleRadioInputChanges(e)}>
                  <input
                    name="mainBal"
                    maxLength="20"
                    type="radio"
                    style={{ marginLeft: 10 }}
                    value='MAIN_WALLET'
                  // onChange={(e) => handleChanges(e)}
                  /> <label>Main Balance  </label>
                  <input
                    name="mainBal"
                    maxLength="20"
                    type="radio"
                    style={{ marginLeft: 25 }}
                    value='VIRTUAL_WALLET'
                  // onChange={(e) => handleChanges(e)}
                  /> <label>Virtual Balance</label>
                </div>
              
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => fetchBill()}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loader-anim"></div>
                  ) : (
                    "Fetch Bill"
                  )}
                </button>

                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-primary"
                  onClick={() => makeTransaction()}
                  disabled={isLoading}
                >
                  {isLoading ? <div className="loader-anim"></div> : "Pay"}
                </button>
              </div>
                          </div>
              <p className="success-text">{billInfo}</p>
              <p className="error-text">{billInfoError}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZpBbps;
