import React, { useState } from 'react'

export const BalanceContext = React.createContext({
  amount: "0",
  setAmount: () => {}
})

export const BalanceContextProvider = (props) => {

  const setAmount = (amount) => {
    setState({...state, amount: amount})
  }

  const initState = {
    amount: "0",
    setAmount: setAmount
  } 

  const [state, setState] = useState(initState)

  return (
    <BalanceContext.Provider value={state}>
      {props.children}
    </BalanceContext.Provider>
  )
}