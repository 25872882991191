import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import moment from "react-moment";
import {
  getSelectedUsersList,
  getV_Balance,
} from "../../../store/actions/onDataResultAction";
import AddUser from "../../../components/add-user-form";
import { BiEdit } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";
import "../retailers/retailer.css";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../../components/loadinghud";

const AdminUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminList, setAdminList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [userData, setUserserData] = useState({});
  const dataFetchedRef = useRef(false);
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    console.log("hello admins 0");
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getAdminList(10, 0);
    console.log("hello admins 11");
    return () => { }
  }, []);

  const getAdminList = async (limit, offset) => {
    console.log("hello admins 1");
    setIsStoring(true);
    await dispatch(
      getSelectedUsersList({ role: 2, limit: limit, offset: offset }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      console.log("hello admins");
      if (response.result) {
        setAdminList(response.result);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getAdminList(10, (pageNumber*10)-10);
  };

  const validatePagination = () => {
    if(activePage <= 1) {
      if(adminList.length > 9) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  const manageUserData = (roleId, userId) => {
    setUserserData({ roleId: roleId, userId: userId });
    setShowAddUserForm(true);
  };

  const usereditClicked = (userInfo) => {
    setUserserData({
      roleId: "4",
      userId: userInfo.id,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
    });
    console.log("usereditClicked", userInfo);
    setShowAddUserForm(true);
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
          {showAddUserForm && (
              <div className="container"
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            height: "100%",
            zIndex: "999",
          }}
        >
          <div className="row">
                      <div className="col-md-6"
                          style={{
                              margin: "0 auto",
                          }}>
              <AddUser
                onClose={() => {
                  console.log("Add User closed");
                  setShowAddUserForm(false);
                  getAdminList(10, 0);
                }}
                parentToChild={userData}
                type="Partner"
              />
            </div>
          </div>
        </div>
      )}
      <div className="container pt-2">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <p className="m-2">Partners</p>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Partners
                </li>
              </ol>
            </nav>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-primary"
              onClick={() => manageUserData("2", "")}
            >
              + Add Partner
            </button>
            {/* <button type="button" className="btn btn-white-bg">Update</button> */}
          </div>
        </div>

        <div className="row m-3">
                  <div className="col-md-12 card-1 ovrscrl">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {adminList && adminList.length > 0 && (
                <tbody>
                  {adminList.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <td className="align-middle">
                        <p>{i+1+((activePage*10) - 10)}</p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.name}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.email}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.phone}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.status}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          {/* <button type="button" className="btn btn-white-bg">Update</button>
                          <button type="button" className="btn btn-white-bg">Delete</button> */}
                          <span className="actions">
                            <BiEdit onClick={() => usereditClicked(item)} />
                            {/* <BsFillTrashFill className="delete-btn" /> */}
                          </span>
                        </td>
                        {/* <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => {
                              navigate(`/order-detail?orderId=${od.id}`);
                            }}
                          >
                            <AiFillEye />
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {
              validatePagination()
                ? <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={50}
                  pageRangeDisplayed={5}
                  onChange={(number) => handlePageChange(number)}
                />
                : <></>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
