import { adminDashBoardDetailsRequest, rDashBoardDetailsRequest, walletBalanceDetailsRequest } from "../../api/dashboardAPI";


const getDashboardDetails = (data) => async (dispatch) => {
    const response = await dispatch(rDashBoardDetailsRequest(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const getVirtualWalletBalance = (data) => async (dispatch) => {
    const response = await dispatch(walletBalanceDetailsRequest(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
}

const getAdminDashboardDetails = (data) => async (dispatch) => {
  const response = await dispatch(adminDashBoardDetailsRequest(data));
if (
  (response &&
    Object.keys(response).length &&
    response?.data?.status == 201) ||
  200
) {
  return Promise.resolve(response.data);
} else {
  return Promise.resolve(null);
}
}


export {
    getDashboardDetails,
    getVirtualWalletBalance,
    getAdminDashboardDetails,
}