import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const getZpayOperators = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ZPAY_REVHARGE_PROVIDERS}${data.type}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

export const getCircleCodes = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ZPAY_CIRCLE_CODES}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

export const payRecharge = (data) => async () => {
  console.log(data);
  try {
    let result = await ServiceCalls.post(`${API_URL.ZPAY_REHARGE}`, data, true);
    return result;
  } catch (error) {}
};

export const getBbpsOptions = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ZPAY_BBPS_OPTIONS}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

export const getBbpsProviders = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ZPAY_BBPS_PROVIDERS}${data.type}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};

export const fetchBill = (data) => async () => {
  console.log(data);
  try {
    let result = await ServiceCalls.post(
      `${API_URL.ZPAY_BBPS_FETCH_BILL}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const payBill = (data) => async () => {
  console.log(data);
  try {
    let result = await ServiceCalls.post(
      `${API_URL.ZPAY_BBPS_PAY_BILL}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const getZRC_plans = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.ZPAY_RC_PLANS}?code=${data.code}&circleCode=${data.circleCode}`,
      null,
      false
    );
    return result;
  } catch (error) {}
};
