import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "./pdfdocument";
import { useEffect } from "react";

function DownloadPdfFile(documentData) {
  useEffect(() => {
    console.log("doc-2");
  }, []);
  return (
    <div>
      <PDFDownloadLink
        document={<PdfDocument invoicedata={documentData} />}
        fileName="somename.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
}

export default DownloadPdfFile;
