import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const addUserRequest = (data) => async () => {
  try {
    let result = await ServiceCalls.post(`${API_URL.ADD_USER}`, data, true);
    return result;
  } catch (error) {}
};

export const updateUserRequest = (data) => async () => {
  try {
    let result = await ServiceCalls.put(`${API_URL.UPDATE_USER}`, data, true);
    return result;
  } catch (error) {}
};

export const updateUserPasswordRequest = (data) => async () => {
  try {
    let result = await ServiceCalls.put(
      `${API_URL.UPDATE_USER_PWD}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const slabListSession = (data) => async () => {
  try {
    let result = await ServiceCalls.get(`${API_URL.SLAB_LIST}?slabId=${data.slabId}`, null, true);
    return result;
  } catch (error) {}
};

export const addOrUpdateSlabSession = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.SLAB_ADD_UPDATE}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const slabBaseListSession = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.SLAB_BASE_LIST}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const addOrUpdateSlabBaseSession = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.SLAB_BASE_UPDATE}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};

export const getUserInfo = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.USER_INFO}${data.id}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};
