import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddSlabBaseRequest } from "../store/actions/onUserDataResultAction";
import { Toast } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHudPopUp from "./loadinghudpopup";

const AddSlabBase = (props) => {
  const dispatch = useDispatch();

  const [addUserForm, setAddUserForm] = useState({
    owner: "",
  });

  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [isStoring, setIsStoring] = useState(false);

  const handleTranferChanges = (e) => {
    console.log("Debug > ", e);
    setAddUserForm({ ...addUserForm, [e.target.name]: e.target.value });
  };

  const addNewBeneficiary = async () => {
    if (addUserForm.name.length === 0) {
      setShowMessage("Enter slab name.");
      setShow(true);
    } else {
      console.log("Initiate transfer");
      setIsStoring(true);
      await dispatch(
        AddSlabBaseRequest(
          {
            name: addUserForm.name,
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          let emptyvals = {
            name: "",
          };
          setAddUserForm(emptyvals);

          setShowMessage(response.message);
          setShow(true);
          props.onClose();
        }
      });
    }
  };

  return (
    <>
      {isStoring && <RingLoadingHudPopUp />}
      <div>
        <div className="container">
          <div className="row mt-2">
            <div className="col-10 card-1 p-5">
              <h3 className="text-center table">Add Slab</h3>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="row justify-content-left">
                    <div className="col">
                      <label>Slab Title</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={addUserForm.name}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{ display: "flex", justifyContent: "center" }}
                      type="button"
                      className="btn btn-purple-bg"
                      onClick={() => addNewBeneficiary()}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-white-bg"
                      onClick={() => {
                        if (props.onClose) props.onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 60,
              right: 200,
            }}
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header className="table" closeButton={false}>
                <strong className="mr-auto">info</strong>
              </Toast.Header>
              <Toast.Body className="table">{showMessage}</Toast.Body>
            </Toast>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlabBase;
