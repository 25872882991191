import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import {
  getV_Balance_ByEntryRequest,
  getWalletBalance,
} from "../../store/actions/onvBalanceDataResultAction";
import RingLoadingHud from "../../components/loadinghud";
import { WalletContext } from "../../components/walletcontext";

const VWalletTransactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [virtualBalanceList, setVirtualBalanceList] = useState([]);
  const [walletBalance, setWalletBalance] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const handleTranferChanges = (e) => { };
  const [isStoring, setIsStoring] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [walletcontext, setWalletContext] = useContext(WalletContext);


  useEffect(() => {
    getWalletTransactions(10, 0);
    getWalletBalanceReq();
    console.log("v b page");
  }, []);

  const getWalletTransactions = async (limit, offset) => {
    setIsStoring(true);
    await dispatch(
      getV_Balance_ByEntryRequest(
        {
          limit: limit,
          offset: offset,
          type: "Debit",
          entry: "WALLET",
          vId: localStorage.getItem("userId"),
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result != null) {
        setVirtualBalanceList(response.result);
      }
    });
  };

  const getWalletBalanceReq = async () => {
    setIsStoring(true);
    await dispatch(
      getWalletBalance(
        {
          vId: localStorage.getItem("userId"),
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result != null) {
        setWalletBalance(response.result);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getWalletTransactions(10, (pageNumber * 10) - 10);
  };

  const validatePagination = () => {
    if (activePage <= 1) {
      if (virtualBalanceList.length > 9) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  const addWallet = () => {
    // setShowProductForm(true)
    // setWalletContext("200");
  }
  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      <div className="container pt-2">
              <div className="row justify-content-around">
        <div class="tble">
                  <div className="col-lg-6 col-12">
          <p>Wallet Transactions</p>
          </div>
          <div className="col-lg-3 row"></div>
          <div className="col-lg-3 col-12 row">
            <h4 className="pageHeading">Available Balance: 
                         ₹{walletBalance}</h4>
                  </div>
          </div>
          <div className="row m-3">
                      <div className="col-md-12 card-1 ovrscrl">
              <table className="table table-hover align-middle">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Added By</th>
                    <th scope="col">Vendor</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                {virtualBalanceList && virtualBalanceList.length > 0 && (
                  <tbody>
                    {virtualBalanceList.map((item, i) => {
                      return (
                        <tr key={i}>
                          {/* <th scope="row">{od.id}</th> */}
                          <th className="align-middle">
                            <p>{i + 1 + ((activePage * 10) - 10)}</p>
                          </th>
                          <td className="align-middle">
                            <p>
                              {item.amount}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.adminName}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.vendorName}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.comments}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.vendor_type}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            {item.status == "YES"
                              ? <div className="status-mark-success"></div>
                              : <div className="status-mark-failure"></div>
                            }
                          </td>
                          <td className="align-middle">
                            <p class="warning-badge">
                              {getDateFormat(item.created_at)}
                            </p>
                          </td>
                          {/* <td className="align-middle">
                              <button
                                type="button"
                                className="btn btn-purple-bg"
                                onClick={() => {
                                  navigate(`/order-detail?orderId=${od.id}`);
                                }}
                              >
                                <AiFillEye />
                              </button>
                            </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {
                validatePagination()
                  ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={50}
                    pageRangeDisplayed={5}
                    onChange={(number) => handlePageChange(number)}
                  />
                  : <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VWalletTransactions;
