import React, { useRef, useState } from "react";
import SegmentedControl from "../../components/segmentedcontrol/segmentedcontroller";
import ZMobileRecharge from "./zmobile";
import ZMobilePostpaid from "./zmobilepostpaid";
import ZDthRecharge from "./zdth";

const ZRecharge = () => {
  const [selectedValue, setSelectedValue] = useState("prepaid");
  const getComponent = (value) => {
    console.log(value);
    setSelectedValue(value);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case "prepaid":
        return <ZMobileRecharge />;
      case "postpaid":
        return <ZMobilePostpaid />;
      case "dth":
        return <ZDthRecharge />;
    }
  };

  return (
    <div className="container pt-2">
      <div className="row justify-content-around m-2">
        <div class="tble">
          <p className="">Recharge</p>
        </div>
        <SegmentedControl
          className='flex-start'
          name="group-1"
          callback={(val) => getComponent(val)}
          defaultIndex={0}
          controlRef={useRef()}
          segments={[
            {
              label: "Prepaid",
              value: "prepaid",
              ref: useRef(),
            },
            {
              label: "Postpaid",
              value: "postpaid",
              ref: useRef(),
            },
            {
              label: "DTH",
              value: "dth",
              ref: useRef(),
            },
          ]}
        />
        {renderSwitch(selectedValue)}
      </div>
    </div>
  );
};


export default ZRecharge;

