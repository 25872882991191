import React, { useContext, useEffect, useRef, useState } from "react";
import "./zpaystyles.css";
import {
  ZPayRCPlansRequest,
  ZpayRechargeRequest,
  getCircleCodeRequest,
  getZpayOperatorsRequest,
} from "./actions/onZpayBankingResultAction";
import { useDispatch } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import AlertView from "../../components/alert-view";
import { updateReportService } from "../../api/reportsAPI";
import { WalletContext } from "../../components/walletcontext";
import { BalanceContext } from "../../components/walletprovider";
import { VirtualBalanceContext } from "../../components/virtualbalanceprovider";
import { getVirtualWalletBalance } from "../dashboard/dashboardrequest";


const ZMobileRecharge = () => {
  const dispatch = useDispatch();
  const [rcForm, setRcForm] = useState({
    amount: "",
    mobile: "",
    operator: "",
    circle: "",
  });
  const [operators, setOPerators] = useState([]);
  const [circles, setCircles] = useState([]);
  const [rcPlans, serRcPlans] = useState([]);
  const [isStoring, setIsStoring] = useState(false);
  const [plans, setPlans] = useState([]);
  const [circleCode, setCircleCode] = useState("");
  const [tabsActiveIndex, setTabsActiveIndex] = useState("");
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [planTabs, setPlanTabs] = useState([]);
  const state = useContext(BalanceContext);
  const vState = useContext(VirtualBalanceContext);
  const [paymentType, setPaymentType] = useState("");

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(getZpayOperatorsRequest({ type: "PREPAID" }, dispatch)).then(
      (response) => {
        setIsStoring(false);
        if (response) {
          setOPerators(response.result);
          getCiecles(response.result[0].code);
        }
      }
    );
  };

  const getCiecles = async (code) => {
    console.log("Get Circles ", code);
    setIsStoring(true);
    await dispatch(getCircleCodeRequest({ type: "PREPAID" }, dispatch)).then(
      (response) => {
        setIsStoring(false);
        if (response) {
          setCircles(response.result);
          setRcForm({ ...rcForm, operator: response.result[0].circle_code });
          // circleCode = response.result[0].circle_code
          setCircleCode(response.result[0].circle_code);
          console.log("circle_code ", response.result[0].circle_code);
          getPlans(code, response.result[0].circle_code);
        }
      }
    );
  };

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleOperatorChanges = (e) => {
    console.log(
      "handleOperatorChanges ",
      e.target.value,
      "circleCode",
      circleCode
    );
    getPlans(e.target.value, circleCode);
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleCircleChanges = (e) => {
    console.log(
      "handleCircleChanges ",
      rcForm.operator,
      "circleCode",
      circleCode,
      "asdf",
      e.target.value
    );
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
    setCircleCode(e.target.value);
    getPlans(rcForm.operator, e.target.value);
  };

  const getPlans = async (code, circleCode) => {
    console.log("Get Plans ", code, circleCode);

    setIsStoring(true);
    await dispatch(
      ZPayRCPlansRequest({ code: code, circleCode: circleCode }, dispatch)
    ).then((response) => {
      setIsStoring(false);

      console.log("resp recvd");
      if (response.result != null) {
        console.log("resp not null");
        serRcPlans(response.result.plans);
        managePlans(response.result.plans);
      } else {
        showAlert(response.message);
        setPlans([]);
        setPlanTabs([]);
        serRcPlans([]);
      }
    });
  };

  const managePlans = (plans) => {
    let tabsArray = [];
    for (let i = 0; i < plans.length; i++) {
      let val = plans[i].planTab;
      // console.log(val);
      if (val === null) {
        val = "Others";
      }
      tabsArray.push(val);
    }
    let array = Array.from(new Set(tabsArray));
    setPlanTabs(array);
    onChangeTab(array[0], plans);
  };

  const updateTransaction = (res, respMessage) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "RECHARGE",
      page: "PREPAID",
      pageOption: "PREPAID",
      loginId: localStorage.getItem("userId"),
      superUserId: localStorage.getItem("superuserId"),
      status: respMessage,
      walletType: paymentType
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {
      getRetaierWalletBalance()
    });
  };

  const handleRadioInputChanges = (e) => {
    console.log(e.target.value);
    setPaymentType(e.target.value);
  };

  const getRetaierWalletBalance = async () => {
    await dispatch(
      getVirtualWalletBalance(
        {
          id: localStorage.getItem("userId")
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        state.setAmount(response.result.mainBalance);
        vState.setVirtualAmount(response.result.virtualBalance);
      } 
    });
  }

  const makeTransaction = (e) => {

    let walletAmountVal = parseInt(state.amount)
    let walletVirtualAmountVal = parseInt(vState.virtualamount)
    let rcAmount  = parseInt(rcForm.amount)
    
    if (rcForm.amount.length === 0) {
      showAlert("Please enter amount");
    } else if (rcForm.mobile.length === 0) {
      showAlert("Please enter mobile number");
    } else if((walletAmountVal < rcAmount) && (walletVirtualAmountVal < rcAmount)) {
      showAlert("Your wallet amount is low. Please recharge");
    } else if (paymentType.length === 0) {
      showAlert("Please select wallet type");
    } else {
      let body = {
        amount: rcForm.amount,
        biller_code: rcForm.operator,
        number: rcForm.mobile,
      };

      dispatch(ZpayRechargeRequest(body, dispatch)).then((response) => {
        console.log(response);
        
        if (response) {
          if (response.status === "false") {
            showAlert("Please enter all fields and try again");
            updateTransaction(response, "fail");
          } else {
            let emptyvals = {
              amount: "",
              mobile: "",
              operator: "",
              circle: "",
            };
            setRcForm(emptyvals);
            showAlert(response.message);
            updateTransaction(response, "success");
          }
        } else {
          showAlert("Please enter all fields and try again");
        }
      });
    }
  };

  const handleListClick = (e) => {
    console.log("hh");
    console.log(e);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setRcForm({ ...rcForm, amount: e.amount });
  };

  const onChangeTab = (e, myPlans = []) => {
    console.log("onChangeTab", e);
    setTabsActiveIndex(e);
    if (myPlans.length > 0) {
      var arrayNull = [];
      if (e == "Others") {
        arrayNull = myPlans.filter((plan) => plan.planTab == null);
      }

      let filterArray = myPlans.filter((plan) => plan.planTab == e);

      for (let i = 0; i < arrayNull.length; i++) {
        const element = arrayNull[i];
        filterArray.push(element);
      }

      setPlans(filterArray);
    } else {
      var arrayNull = [];
      if (e == "Others") {
        arrayNull = rcPlans.filter((plan) => plan.planTab == null);
      }

      let filterArray = rcPlans.filter((plan) => plan.planTab == e);

      for (let i = 0; i < arrayNull.length; i++) {
        const element = arrayNull[i];
        filterArray.push(element);
      }

      setPlans(filterArray);
    }
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          style={{ width: 0 }}
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      
        <div className="container">
          <div className="row align-items-start mb-4">
            <div className="col-lg-4 col-12 mt-0 formcontainer ncard py-2 px-2">
              <div>
                <label>Operator *</label>
                <select
                  name="operator"
                  className="form-control"
                  onChange={(e) => handleOperatorChanges(e)}
                >
                  {operators.map((e, key) => {
                    return (
                      <option key={key} value={e.code}>
                        {e.service}
                      </option>
                    );
                  })}
                </select>

                <label>Circle *</label>
                <select
                  name="circle"
                  className="form-control"
                  onChange={(e) => handleCircleChanges(e)}
                >
                  {circles.map((e, key) => {
                    return (
                      <option key={key} value={e.circle_code}>
                        {e.circle_name}
                      </option>
                    );
                  })}
                </select>
                <label>Mobile Number *</label>
                <input
                  type="number"
                  name="mobile"
                  maxLength="20"
                  className="form-control"
                  value={rcForm.mobile}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Amount *</label>
                <input
                  name="amount"
                  maxLength="20"
                  type="number"
                  className="form-control"
                  value={rcForm.amount}
                  onChange={(e) => handleChanges(e)}
                />
                <label>Wallet Mode *</label>
                <div onChange={(e) => handleRadioInputChanges(e)}>
                  <input
                    name="mainBal"
                    maxLength="20"
                    type="radio"
                    style={{ marginLeft: 10 }}
                    value='MAIN_WALLET'
                  // onChange={(e) => handleChanges(e)}
                  /> <label>Main Balance  </label>
                  <input
                    name="mainBal"
                    maxLength="20"
                    type="radio"
                    style={{ marginLeft: 25 }}
                    value='VIRTUAL_WALLET'
                  // onChange={(e) => handleChanges(e)}
                  /> <label>Virtual Balance</label>
                </div>
                <div className="mt-3">
                  <button
                    style={{ justifyContent: "center" }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => makeTransaction()}
                  >
                    Proceed to Pay
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-8 col-12 formcontainer"
              style={{ marginTop: 0 }}
            >
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={tabsActiveIndex}
                onSelect={(e) => {
                  onChangeTab(e);
                }}
              >
                {planTabs.map((plan) => (
                  <Tab eventKey={plan} title={plan}></Tab>
                ))}
              </Tabs>
              <div style={{ maxHeight: 500, overflow: "scroll" }}>
                <table className="table table-hover align-middle">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Talk Time in INR</th>
                      <th>Validity</th>
                      <th>Description</th>
                      <th>Price in INR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plans.map((plan) => (
                      <tr key={plan.id}>
                        <td>{plan.planName}</td>
                        <td>{plan.talktime}</td>
                        <td>{plan.validity}</td>
                        <td style={{ maxWidth: 350 }}>
                          {plan.planDescription}
                        </td>
                        <td style={{ minWidth: 100 }}>
                          <button
                            className="roundButton"
                            onClick={() => handleListClick(plan)}
                          >
                            Rs {plan.amount}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};
export default ZMobileRecharge;
