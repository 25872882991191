import React, { useState } from 'react'

export const VirtualBalanceContext = React.createContext({
  virtualamount: "0",
  setVirtualAmount: () => {}
})

export const VirtualBalanceContextProvider = (props) => {

  const setVirtualAmount = (virtualamount) => {
    setState({...state, virtualamount: virtualamount})
  }

  const initState = {
    virtualamount: "0",
    setVirtualAmount: setVirtualAmount
  } 

  const [state, setState] = useState(initState)

  return (
    <VirtualBalanceContext.Provider value={state}>
      {props.children}
    </VirtualBalanceContext.Provider>
  )
}