import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ZPayRCPlansRequest,
  ZpayRechargeRequest,
  getZpayOperatorsRequest,
} from "./actions/onZpayBankingResultAction";
import { Tab, Tabs } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import { updateReportService } from "../../api/reportsAPI";
import { BalanceContext } from "../../components/walletprovider";
import AlertView from "../../components/alert-view";
import { VirtualBalanceContext } from "../../components/virtualbalanceprovider";
import { getVirtualWalletBalance } from "../dashboard/dashboardrequest";

const ZDthRecharge = () => {
  const dispatch = useDispatch();
  const [showDthPlanForm, setshowDthPlanForm] = useState(false);
  const [operators, setOPerators] = useState([]);
  const [rcForm, setRcForm] = useState({
    amount: "",
    mobile: "",
    operator: "",
  });
  const [rcPlans, serRcPlans] = useState([]);
  const [isStoring, setIsStoring] = useState(false);
  const state = useContext(BalanceContext);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const vState = useContext(VirtualBalanceContext);
  const [paymentType, setPaymentType] = useState("");

  useEffect(() => {
    getOperators();
    console.log("mobile rc page");
  }, []);

  const getOperators = async () => {
    setIsStoring(true);
    await dispatch(getZpayOperatorsRequest({ type: "DTH" }, dispatch)).then(
      (response) => {
        setIsStoring(false);
        if (response) {
          console.log(response.data);
          setOPerators(response.result);
          getPlans(response.result[0].code);
        }
      }
    );
  };

  const updateTransaction = (res, respMessage) => {
    console.log("SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "ZPAY",
      amount: rcForm.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "RECHARGE",
      page: "DTH",
      pageOption: "PREPAID",
      loginId: localStorage.getItem("userId"),
      superUserId: localStorage.getItem("superuserId"),
      status: respMessage,
      walletType: paymentType
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {
      getRetaierWalletBalance()
     });
  };

  const getRetaierWalletBalance = async () => {
    await dispatch(
      getVirtualWalletBalance(
        {
          id: localStorage.getItem("userId")
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        state.setAmount(response.result.mainBalance);
        vState.setVirtualAmount(response.result.virtualBalance);
      } 
    });
  }

  const handleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });
  };

  const handleCircleChanges = (e) => {
    setRcForm({ ...rcForm, [e.target.name]: e.target.value });

    getPlans(e.target.value);
  };

  const getPlans = async (code) => {
    setIsStoring(true);
    await dispatch(
      ZPayRCPlansRequest({ code: code, circleCode: "" }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result != null) {
        serRcPlans(response.result.plans);
      } else {
        serRcPlans([]);
      }
    });
  };

  const makeTransaction = (e) => {
    let walletAmountVal = parseInt(state.amount)
    let walletVirtualAmountVal = parseInt(vState.virtualamount)
    let rcAmount  = parseInt(rcForm.amount)
    if (rcForm.amount.length === 0) {
      showAlert("Please enter amount");
    } else if (rcForm.mobile.length === 0) {
      showAlert("Please enter box number");
    } else if((walletAmountVal < rcAmount) && (walletVirtualAmountVal < rcAmount)) {
      showAlert("Your wallet amount is low. Please recharge");
    } else {
      let body = {
        amount: rcForm.amount,
        biller_code: rcForm.operator,
        number: rcForm.mobile,
      };

      dispatch(ZpayRechargeRequest(body, dispatch)).then((response) => {
        console.log(response);
        if (response) {
          
          if (response.status === "false") {
            alert("Please enter all fields and try again");
            updateTransaction(response, "fail");
          } else {
            let emptyvals = {
              amount: "",
              mobile: "",
              operator: "",
            };
            setRcForm(emptyvals);
            alert(response.message);
            updateTransaction(response, "success");
          }
        } else {
          alert("Please enter all fields and try again");
        }
      });
    }
  };

  const handleListClick = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setRcForm({ ...rcForm, amount: e.amount });
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  const handleRadioInputChanges = (e) => {
    console.log(e.target.value);
    setPaymentType(e.target.value);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          style={{ width: 0 }}
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      <div>
        <div className="container">
          <div className="row align-items-start">
            <div
                          className="col-lg-4 col-12 mt-0 justify-content-around formcontainer ncard py-2 px-2"
              id="transfer"
            >
              <label>Provider</label>
              <select
                name="operator"
                className="form-control"
                onChange={(e) => handleCircleChanges(e)}
              >
                {operators.map((e, key) => {
                  return (
                    <option key={key} value={e.code}>
                      {e.service}
                    </option>
                  );
                })}
              </select>
              <label>DTH Box Number</label>
              <input
                name="mobile"
                type="number"
                className="form-control"
                value={rcForm.mobile}
                onChange={(e) => handleChanges(e)}
              />
              <label>Amount</label>
              <input
                name="amount"
                type="number"
                className="form-control"
                value={rcForm.amount}
                onChange={(e) => handleChanges(e)}
              />
              <label>Wallet Mode *</label>
                <div onChange={(e) => handleRadioInputChanges(e)}>
                  <input
                    name="mainBal"
                    maxLength="20"
                    type="radio"
                    style={{ marginLeft: 10 }}
                    value='MAIN_WALLET'
                  // onChange={(e) => handleChanges(e)}
                  /> <label>Main Balance  </label>
                  <input
                    name="mainBal"
                    maxLength="20"
                    type="radio"
                    style={{ marginLeft: 25 }}
                    value='VIRTUAL_WALLET'
                  // onChange={(e) => handleChanges(e)}
                  /> <label>Virtual Balance</label>
                </div>
              {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
              <button
                style={{ display: "flex", justifyContent: "center" }}
                type="button"
                className="btn btn-primary"
                onClick={() => makeTransaction()}
              >
                Pay
              </button>
              {/* </div> */}
            </div>
            <div
              className="col-lg-8 col-12 formcontainer"
              style={{ marginTop: 0 }}
            >
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Plans">
                  {/* <div>
                  {rcPlans.map(plan => (
                    <li onClick={() => {handleListClick(plan)}}>
                      {plan.planName} {"<>"} {plan.amount}
                    </li>
                  ))}
                </div> */}
                                  <div style={{ maxHeight: 500, overflow: "scroll" }}>
                  <table className="table table-hover align-middle">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Validity</th>
                        <th>Description</th>
                        <th>Price in INR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rcPlans.map((plan) => (
                        <tr key={plan.id}>
                          <td>{plan.planName}</td>
                          <td>{plan.validity}</td>
                          <td>{plan.planDescription}</td>
                          <td>
                            <button
                              className="roundButton"
                              onClick={() => handleListClick(plan)}
                            >
                              Rs {plan.amount}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                                  </table>
</div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZDthRecharge;
