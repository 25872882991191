import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import PayMentStatus from "../../components/payment-status-popup";
import { updateReportService } from "../../api/reportsAPI";
import { BalanceContext } from "../../components/walletprovider";
import { getVirtualWalletBalance } from "../dashboard/dashboardrequest";
import { VirtualBalanceContext } from "../../components/virtualbalanceprovider";

const PayIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transactionLog, setTransactionLog] = useState([]);
  const [balanceTotal, setBalanceTotal] = useState({});
  const [activePage, setActivePage] = useState(1);
  // const [paymentStatus, setPaymentStatus] = useState("");
  // const [showStatus, setShowStatus] = useState(false);
  const [payinForm, setPayinForm] = useState({
    name: "",
    amount: "",
    mobile: "",
    email: "",
  });
  const state = useContext(BalanceContext);
  const vState = useContext(VirtualBalanceContext);
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [timePassed, setTimePassed] = useState(false);
  let timer1 = setTimeout(() => setTimePassed(true), 4000);

  useEffect(() => {
    console.log("useEffect PAY_IN");
    window.addEventListener("errorCallbackUrl", (data) => {
      // if (reload == true) {
      console.log("is reload ");
      if (data != null) {
        // setReload(false);
        console.log("useEffect payIN", data);
        let res = data.detail;
        if (
          typeof res != "undefined" &&
          typeof res.paymentMethod != "undefined" &&
          typeof res.paymentMethod.paymentTransaction != "undefined" &&
          typeof res.paymentMethod.paymentTransaction.statusCode !=
            "undefined" &&
          res.paymentMethod.paymentTransaction.statusCode == "0300"
        ) {
          // success block
          updateTransaction(res, "success");
        } else if (
          typeof res != "undefined" &&
          typeof res.paymentMethod != "undefined" &&
          typeof res.paymentMethod.paymentTransaction != "undefined" &&
          typeof res.paymentMethod.paymentTransaction.statusCode !=
            "undefined" &&
          res.paymentMethod.paymentTransaction.statusCode == "0398"
        ) {
          updateTransaction(res, "success");
          // initiated block
        } else {
          updateTransaction(res, "success");
          // error block
        }
      }
      // }
    });
    console.log(`The count is ${count}`);
  }, []);

  const updateTransaction = (res, resStatus) => {
    console.log("updateTransaction SDebug > ", res);

    // statusCode: res.paymentMethod.paymentTransaction.statusCode,
    //   statusMessage: res.paymentMethod.paymentTransaction.statusMessage,
    //   identifier: res.paymentMethod.paymentTransaction.identifier,
    //   meta: res.merchantResponseString,
    let body = {
      provider: "Worldline",
      amount: res.paymentMethod.paymentTransaction.amount,
      userId: localStorage.getItem("userId"),
      transaction_meta: JSON.stringify(res),
      entryType: "PAY_IN",
      page: "PAY_IN",
      pageOption: "PAY_IN",
      status: resStatus,
      loginId: localStorage.getItem("userId"),
      superUserId: localStorage.getItem("superuserId"),
      refId: res.paymentMethod.paymentTransaction.identifier,
    };

    dispatch(updateReportService(body, dispatch)).then((response) => {
      getRetaierWalletBalance(resStatus);
    });
  };

  const makePament = async () => {
    if (payinForm.name.length == 0) {
      alert("Enter Name");
      return;
    } else if (payinForm.amount.length === 0) {
      alert("Enter amount.");
    } else if (payinForm.mobile.length === 0) {
      alert("Enter mobile number.");
    } else if (payinForm.email.length === 0) {
      alert("Enter email id.");
    } else {
      // setReload(true);
      const pnc = window.test(payinForm);
      console.log(pnc);
    }
  };

  React.useEffect(() => {});

  const handleChanges = (e) => {
    console.log("SDebug > ", e.target.value);
    setPayinForm({ ...payinForm, [e.target.name]: e.target.value });
  };

  const getRetaierWalletBalance = async (status) => {
    console.log(" status ", status);
    let emptyvals = { name: "", amount: "", mobile: "", email: "" };
    setPayinForm(emptyvals);
    // if (status == "success") {
    //   setPaymentStatus("Success");
    //   setShowStatus(true);
    // } else {
    //   setPaymentStatus("Failure");
    //   setShowStatus(true);
    // }
    await dispatch(
      getVirtualWalletBalance(
        {
          id: localStorage.getItem("userId"),
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        state.setAmount(response.result.mainBalance);
        vState.setVirtualAmount(response.result.virtualBalance);
      }
    });
  };

  return (
    <>
      {/* {showStatus && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <PayMentStatus
                status={paymentStatus}
                onClose={() => {
                  setShowStatus(false);
                }}
              />
            </div>
          </div>
        </div>
      )} */}

      <div>
        <div className="container pt-2">
          <div className="row justify-content-around">
            <div class="tble">
              <p className="">PayIn</p>
            </div>
            <div className="formcontainer" id="transfer">
              <div className="row justify-content-around m-2 ncard py-3 px-3">
                <div className="col-lg-6 col-12">
                  <label>Payee name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={payinForm.name}
                                      onChange={(e) => handleChanges(e)}
                    placeholder="Payee Name"
                  />
                  <label>Amount</label>
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    value={payinForm.amount}
                                      onChange={(e) => handleChanges(e)}
                                      placeholder="Amount"
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="mobile"
                                      className="form-control"
                    value={payinForm.ifsc}
                                      onChange={(e) => handleChanges(e)}
                                      placeholder="Mobile Number"
                  />
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={payinForm.email}
                                      onChange={(e) => handleChanges(e)}
                                      placeholder="Email"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: 200,
                    }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => makePament()}
                  >
                    Transfer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayIn;
