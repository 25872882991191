import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getReportsRequest } from "../../../store/actions/onReportsResults";
import Pagination from "react-js-pagination";
import RingLoadingHud from "../../../components/loadinghud";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AlertView from "../../../components/alert-view";
import RRechargesReports from "./rrechargereports";
import DRechargesReports from "./drechargereports";
import PRechargesReports from "./prechargereports";
import AdminRechargesReports from "./AdminRechargereports";

const RechargesReports = () => {
  const roleId = localStorage.getItem("user_role_id");
  if (roleId === "4") {
    return <RRechargesReports />;
  } else if (roleId === "3") {
    return <DRechargesReports />;
  } else if (roleId === "2") {
    return <PRechargesReports />;
  } else {
    return <AdminRechargesReports />;
  }
};

export default RechargesReports;
