import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBalanceAction } from "../store/actions/onvBalanceDataResultAction";
import AlertView from "./alert-view";

const UpdateBalance = (props) => {
  const dispatch = useDispatch();
  //   const [sectionList, setSectionList] = useState(props.sectionList);
  const [updateBalance, setUpdateBalance] = useState(props.updateBalance);

  const [productAddForm, setproductAddForm] = useState({
    amount: "",
    comments: "",
  });
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const addProduct = (e) => {
    console.log("SDebug > ", productAddForm);
    if (productAddForm.amount.length === 0) {
      setAlertMessage("Please enter amount");
      setIsAlertView(true);
    } else {
      let body = {
        amount: productAddForm.amount,
        comments: productAddForm.comments,
        adminId: localStorage.getItem("userId"),
      };

      dispatch(updateBalanceAction(body, dispatch)).then((response) => {
        console.log("productList", response.result);
        if (response.result) {
          props.onClose();
        }
      });
    }
  };

  const onSectionSelect = (sectionId) => { };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    if (e.target.name === "sectionId") {
    }

    setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  return (
    <>
    {
        isAlertView && (
          <AlertView
          message={alertMessage}
          onClose = {()=> {
            setIsAlertView(false);
          }}
        />
        )
      }
      <div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-10 card-1 p-5">
              <h3 className="text-center">Add/Update Virtual Balance</h3>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Amount</label>
                  <input
                    type="number"
                    name="amount"
                    className="form-control"
                    value={productAddForm.amount}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter amount"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Comments</label>
                  <textarea
                    className="form-control"
                    name="comments"
                    rows="3"
                    value={productAddForm.comments}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter Comments"
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 text-center mt-4">
                <button
                  className="btn btn-primary"
                  onClick={(e) => addProduct(e)}
                >
                  Submit
                </button>
                <button
                  className="btn btn-white-bg"
                  onClick={() => {
                    if (props.onClose) props.onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateBalance;
