import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import { slabBaseListRequest, slabListRequest } from "../../store/actions/onUserDataResultAction";
import UpdateBalance from "../../components/update-balance-form";
import AddSlab from "../../components/add-slab";
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../components/loadinghud";
import AddSlabBase from "../../components/add-slab-base";

const Slabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [baseSlabsList, setbaseSlabsList] = useState([]);
  const [slabsList, setslabsList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showSlabForm, setShowSlabForm] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [showAddSlabButton, setShowAddSlabButton] = useState(false);
  const [selectedSlab, setSelectedSlab] = useState({});

  useEffect(() => {
    getBaseSlabs();
  }, []);

  const getBaseSlabs = async (limit, offset) => {
    setIsStoring(true);
    await dispatch(
      slabBaseListRequest({ limit: limit, offset: offset }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        // getVirtualBalance(10, 0);
        setbaseSlabsList(response.result);
      } else {
        setbaseSlabsList([]);
      }
    });
  };

  const showSlabs = (e) => {
    setSelectedSlab(e);
    setShowAddSlabButton(true);
    getSlabs(e);
  }

  const getSlabs = async (e) => {
    console.log(e);
    console.log(selectedSlab);
    setIsStoring(true);
    await dispatch(
      slabListRequest({ limit: 100, offset: 0, slabId: e.id }, dispatch)
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setslabsList(response.result);
      } else {
        setslabsList([]);
      }
    });
  }

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    // getVirtualBalance(10, pageNumber * 10 - 10);
  };

  const validatePagination = () => {
    if (activePage <= 1) {
      if (slabsList.length > 9) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  const addSlab = () => {
    setShowSlabForm(true);
  }

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {showProductForm && (
        <div
                  className="modlpup"
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-6">
              <AddSlabBase
                onClose={() => {
                  setShowProductForm(false);
                  getBaseSlabs();
                }}
              />
            </div>
          </div>
        </div>
      )}
      {showSlabForm && (
              <div
                  className="modlpup"
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-5">
              <AddSlab
                onClose={() => {
                  setShowSlabForm(false);
                  getSlabs(selectedSlab);
                }}
                slabDetails={selectedSlab}
              />
            </div>
          </div>
        </div>
      )}
      <div className="container pt-2">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <p className="">Slabs</p>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Slabs
                </li>
              </ol>
            </nav>
          </div>
          <div className="mr-auto col-6 text-end">
            <button
              type="button"
              className="mr-auto btn btn-primary"
              onClick={() => setShowProductForm(true)}
            >
              + Add Slot
            </button>
            {/* <button type="button" className="btn btn-white-bg">Update</button> */}
          </div>
        </div>


        <div className="row">
          <div className="col-md-4 col-12 ">
                      <div className="ncard p-2 ovrscrl">
                      <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Slabs</th>
                </tr>
              </thead>
              {baseSlabsList && baseSlabsList.length > 0 && (
                <tbody>
                  {baseSlabsList.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <th className="align-middle">
                          <p>{i + 1 + (activePage * 10 - 10)}</p>
                        </th>
                        <td className="align-middle">
                          <p>
                            {item.name}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td>
                          <button
                            type="button"
                                    className="mr-auto btn btn-primary"
                            onClick={() => showSlabs(item)}
                          >
                            Slabs
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
          </div>
          <div className="col-md-8 col-12">
                      <div className="row  ncard p-2 ovrscrl">
              <h6 className='col-6'>Slabs</h6>
              {showAddSlabButton && (
                <button
                  className="btn btn-primary col-5"
                  onClick={() => addSlab()}
                >
                  + Add Slab
                </button>)}
            
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">S. No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Partner</th>
                  <th scope="col">Distributor</th>
                  <th scope="col">Retailer</th>
                </tr>
              </thead>
              {slabsList && slabsList.length > 0 && (
                <tbody>
                  {slabsList.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <th className="align-middle">
                          <p>{i + 1 + (activePage * 10 - 10)}</p>
                        </th>
                        <td className="align-middle">
                          <p>
                            {item.name}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.owner}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.partner}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.distributor}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.retailer}
                            {/* {getDateFormat(od.created_at)} */}
                          </p>
                        </td>

                        {/* <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-purple-bg"
                            onClick={() => {
                              navigate(`/order-detail?orderId=${od.id}`);
                            }}
                          >
                            <AiFillEye />
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
                          </table>
                      </div>
            {/* {validatePagination() ? (
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={50}
                pageRangeDisplayed={5}
                onChange={(number) => handlePageChange(number)}
              />
            ) : (
              <></>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Slabs;
