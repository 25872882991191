import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getReportsRequest } from "../../../store/actions/onReportsResults";
import Pagination from "react-js-pagination";
import RingLoadingHud from "../../../components/loadinghud";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AlertView from "../../../components/alert-view";

const AdminBillpaymentReports = () => {
  const dispatch = useDispatch();
  const [payoutForm, setPayoutForm] = useState({
    limit: "10",
    status: "",
    userId: "",
    tDate: "",
    startDate: "",
    endDate: "",
    page: "",
  });

  const [payoutReports, setPayoutReports] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [isStoring, setIsStoring] = useState(false);
  const [timePassed, setTimePassed] = useState(false);
  let timer1 = (() => setTimePassed(true), 4000);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const filters = [
    { id: "1", title: "10" },
    { id: "2", title: "20" },
    { id: "3", title: "50" },
  ];

  useEffect(() => {
    getReports(true);
  }, []);

  const handleUserChanges = (e) => {
    console.log("handleUserChanges");
    setPayoutForm({ ...payoutForm, [e.target.name]: e.target.value });
  };

  const getReports = async (flag = false) => {
    console.log("getReports");
    setIsStoring(true);
    const requestObj = flag
      ? {
        entryType: "BBPS",
        limit: payoutForm.limit,
        offset: "",
        status: payoutForm.status,
        userId: payoutForm.userId,
        page: payoutForm.page,
        tDate: payoutForm.tDate,
        startDate: payoutForm.startDate,
        endDate: payoutForm.endDate,
      }
      : {
        entryType: "BBPS",
        limit: "",
        offset: "",
        status: "",
        userId: "",
        page: "",
        tDate: "",
        startDate: "",
        endDate: "",
      };
    await dispatch(getReportsRequest(requestObj, dispatch)).then((response) => {
      setIsStoring(false);
      console.log(response.result, "PAY_OUT");
      if (response.result) {
        setPayoutReports(response.result);
      } else {
        showAlert(response.message);
        setPayoutReports([]);
      }
    });
  };

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  const emptyForm = () => {
    let emptyValues = {
      limit: "10",
      status: "",
      userId: "",
      tDate: "",
      startDate: "",
      endDate: "",
      page: "",
    };
    getReports(false);
  };

  const manageReports = () => {
    getReports(true);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getReports(true);
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}

      <div className="container pt-2">
        <div className="row justify-content-around">

          <div className="formcontainer" id="transfer">
            <div class="tble">
              <p className="">BillPayment Reports</p>
            </div>
            <div className="row justify-content-left m-3 ncard p-3">
              <div className="row justify-content-left">
                <div className="col-lg-3 col-4">
                  <label>Limit</label>
                  <select
                    name="limit"
                    className="form-control"
                    value={payoutForm.limit}
                    onChange={(e) => handleUserChanges(e)}
                  >
                    {filters.map((e, key) => {
                      return (
                        <option key={key} value={e.type}>
                          {e.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-lg-3 col-12">
                  <label>User Phone</label>
                  <input
                    type="text"
                    name="userId"
                    className="form-control"
                    value={payoutForm.userId}
                    placeholder="Type here"
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
                {/* <div className="col-3">
                  <label>Status</label>
                  <input
                    type="text"
                    name="status"
                    className="form-control"
                    value={payoutForm.status}
                    placeholder="Type here"
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
                <div className="col-3">
                  <label>Page</label>
                  <input
                    type="text"
                    name="page"
                    className="form-control"
                    value={payoutForm.page}
                    placeholder="Type here"
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
                <div className="col-3">
                  <label>Transaction Date</label>
                  <input
                    type="date"
                    name="tDate"
                    className="form-control"
                    value={payoutForm.tDate}
                    placeholder="Type here"
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div> */}
                <div className="col-lg-3 col-12">
                  <label>Start Date</label>
                  <input
                    type="date"
                    name="startDate"
                    className="form-control"
                    value={payoutForm.startDate}
                    placeholder="Type here"
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
                <div className="col-lg-3 col-12">
                  <label>End Date</label>
                  <input
                    type="date"
                    name="endDate"
                    className="form-control"
                    value={payoutForm.endDate}
                    placeholder="Type here"
                    onChange={(e) => handleUserChanges(e)}
                  />
                </div>
              </div>
              <div className="row justify-content-left">
                <div className="col-lg-1 col-5 m-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => manageReports()}
                  >
                    Search
                  </button>
                </div>
                <div className="col-lg-1 col-5 m-1">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => emptyForm()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row m-3">
              <div className="col-md-12 card-1 ovrscrl">
                <table className="table table-hover align-middle">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Amount</th>
                      <th scope="col">User Id</th>
                      <th scope="col">Provider</th>
                      <th scope="col">Comments</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  {payoutReports && payoutReports.length > 0 && (
                    <tbody>
                      {payoutReports.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th className="align-middle">
                              <p>{i + 1}</p>
                            </th>
                            <td className="align-middle">
                              <p>{item.amount}</p>
                            </td>
                            <td className="align-middle">
                              <p>{item.user_id}</p>
                            </td>
                            <td className="align-middle">
                              <p>{item.provider}</p>
                            </td>
                            <td className="align-middle">
                              <p>{item.comments}</p>
                            </td>
                            <td className="align-middle">
                              <p>{item.entry_type}</p>
                            </td>
                            <td
                              className="align-middle"
                              style={{ textAlign: "center" }}
                            >
                              {item.status == "success" ? (
                                <p className="status-report-success">
                                  {item.status}
                                </p>
                              ) : (
                                <p className="status-report-failure">
                                  {item.status}
                                </p>
                              )}
                            </td>
                            <td className="align-middle">
                              <p>{getDateFormat(item.created_at)}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
                {payoutReports.length > 100 ? (
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={50}
                    pageRangeDisplayed={5}
                    onChange={(number) => handlePageChange(number)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBillpaymentReports;
