import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserReq,
  updateUserReq,
} from "../store/actions/onUserDataResultAction";
import AlertView from "./alert-view";
import { Alert } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHudPopUp from "./loadinghudpopup";

const AddUser = (props) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(props.parentToChild);
  const [usertype, setUserType] = useState(props.type);
  const [isStoring, setIsStoring] = useState(false);

  const [productAddForm, setProductAddForm] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  useEffect(() => {
    if (userData != null) {
      let updateValues = {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
      };
      setProductAddForm(updateValues);
    }
  }, []);

  const addUser = (e) => {
    if (productAddForm.name == "") {
      showAlert("Please enter name");
    } else if (productAddForm.email == "") {
      showAlert("Please enter email");
    } else if (productAddForm.phone == "") {
      showAlert("Please phone number");
    } else {
      console.log("add user else", productAddForm);
      setIsStoring(true);
      if (userData.userId != "") {
        let body = {
          name: productAddForm.name,
          email: productAddForm.email,
          password: productAddForm.phone,
          role_id: userData.roleId,
          phone: productAddForm.phone,
          status: "ACTIVE",
          superUserId: localStorage.getItem("userId"),
          id: userData.userId,
        };

        dispatch(updateUserReq(body, dispatch)).then((response) => {
          setIsStoring(false);
          if (response.result) {
            console.log(response.status);
            if (response.status == false) {
              alert(response.message);
            } else {
              props.onClose(response.result);
            }
          }
        });
      } else {
        setIsStoring(true);
        let body = {
          name: productAddForm.name,
          email: productAddForm.email,
          password: productAddForm.phone,
          role_id: userData.roleId,
          phone: productAddForm.phone,
          status: "ACTIVE",
          superUserId: localStorage.getItem("userId"),
        };

        dispatch(addUserReq(body, dispatch)).then((response) => {
          setIsStoring(false);
          if (response.result) {
            console.log(response.status);
            if (response.status == false) {
              let userResp = JSON.parse(response.result);
              userResp.email != null ? alert(userResp.email) : userResp.phone != null ? alert(userResp.phone) : alert(response.message)
            } else {
              props.onClose(response.result);
            }
          }
        });
      }
    }
  };

  const onSectionSelect = (sectionId) => { };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    if (e.target.name === "sectionId") {
    }
    setProductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const showAlert = (message) => {
    <AlertView
      message={userData}
    />
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHudPopUp />
      )}
      <div>
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-10 card-1 p-3">
              <p className="text-center tble">
                {userData.name != null ? "Update" : "Add"} {usertype}
              </p>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={productAddForm.name}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter name"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={productAddForm.email}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter email"
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control"
                    value={productAddForm.phone}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    value={productAddForm.password}
                    onChange={(e) => handleChanges(e)}
                    placeholder="Enter password"
                  />
                </div>
              </div> */}
              <div className="col-md-12 text-center mt-4">
                <button className="btn btn-primary" onClick={(e) => addUser(e)}>
                  Submit
                </button>
                <button
                  className="btn btn-white-bg"
                  onClick={() => {
                    if (props.onClose) props.onClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
