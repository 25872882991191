import React, { useContext, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import AddBeneficiary from "../../components/add-beneficiaryform";
import AddCustomer from "../../components/add-customer-form";
import RingLoadingHud from "../../components/loadinghud";
import { getBeneficiaryListRequest, getBeneficiaryUsers } from "../recharge/actions/onBankingResultAction";

const Beneficiaries = () => {
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([]);
    const [beneficiartList, setBeneficiartList] = useState([]);
    const [isStoring, setIsStoring] = useState(false);
    const [beneficiaryData, setBeneficiaryData] = useState({});
    const [showAddBeneficiaryForm, setShowAddBeneficiaryForm] = useState(false);
    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [userData, setUserData] = useState({});

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        setIsStoring(true);
        await dispatch(
            getBeneficiaryUsers(
                {
                    userId: localStorage.getItem("userId"),
                },
                dispatch
            )
        ).then((response) => {
            setIsStoring(false);
            if (response.status === true) {
                setUserList(response.result);
            } else {
                alert(response.message);
            }
        })
    }

    const getBeneficiarydetails = async (id) => {
        setIsStoring(true);
        await dispatch(
            getBeneficiaryListRequest(
                {
                    userId: id,
                    addedBy: localStorage.getItem("userId"),
                },
                dispatch
            )
        ).then((response) => {
            setIsStoring(false);
            if (response.status === true) {
                setBeneficiartList(response.result);
            } else {
                alert(response.message);
            }
        });
    };

    const addUser = () => {
        setShowAddUserForm(true);
    };

    const addBeneficiary = () => {
        setBeneficiaryData({});
        setShowAddBeneficiaryForm(true);
    };


    const editBeneficiary = (e) => {
        setBeneficiaryData(e);
        setShowAddBeneficiaryForm(true);
    }

    const getUserAccounts = (e) => {
        setUserData(e);
        getBeneficiarydetails(e.id)
    }

    return (
        <>
            {isStoring && <RingLoadingHud />}
            {showAddUserForm && (
                <div
                    className="modlpup"
                >
                    <div className="d-flex align-item-center justify-content-center">
                        <div className="col-md-6">
                            <AddCustomer
                                onClose={(result) => {
                                    getUsers();
                                    setShowAddUserForm(false);
                                }}
                                parentToChild={{
                                    roleId: "5",
                                    userId: localStorage.getItem("userId"),
                                }}
                                type="Customer"
                            />
                        </div>
                    </div>
                </div>
            )}
            {showAddBeneficiaryForm && (
                <div
                    className="modlpup"
                >
                    <div className="d-flex align-item-center justify-content-center">
                        <div className="col-md-8">
                            <AddBeneficiary
                                onClose={() => {
                                    console.log("Add User closed");
                                    setShowAddBeneficiaryForm(false);
                                    getBeneficiarydetails(userData.id);
                                }}
                                parentToChild={userData}
                                beneficiaryData={beneficiaryData}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="container pt-2">
                <div className="row justify-content-around">
                    <div class="tble">
                        <p className="">Beneficiaries</p>
                    </div>
                    <div className="formcontainer" id="transfer">

                        <div className="row mt-3">
                            <div className="col-lg-4 col-12">
                                <div className="row ncard p-2 ovrscrl">
                                    <h6 className="col-lg-6 col-6 mt-3">USERS</h6>
                                    <button
                                        className="btn btn-primary col-lg-5 col-5 space-around"
                                        onClick={() => addUser()}
                                    >
                                        + Add User
                                    </button>
                              
                                <table className="table table-hover align-middle">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Phone No.</th>
                                            <th scope="col">Choose</th>
                                        </tr>
                                    </thead>
                                    {userList && userList.length > 0 && (
                                        <tbody>
                                            {userList.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th className="align-middle">
                                                            <p>{i+1}</p>
                                                        </th>
                                                        <td className="align-middle">
                                                            <p>{item.name}</p>
                                                        </td>
                                                        <td className="align-middle">
                                                            <p>{item.phone}</p>
                                                        </td>
                                                        <td className="align-middle">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => getUserAccounts(item)}
                                                            >
                                                                Select
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    )}
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-7 col-12  ml25">
                                <div className="row ncard p-2">
                                    <h6 className='col-lg-6 col-5 mt-3'>BENEFICIARIES</h6>
                                    <button
                                        className="btn btn-primary col-lg-5 col-6 space-around"
                                        onClick={() => addBeneficiary()}
                                    >
                                        + Add Beneficiaries
                                    </button>
                                    <div className="row ovrscrl">
                                <table className="table table-hover align-middle">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Bank</th>
                                            <th scope="col">Account Number</th>
                                            <th scope="col">IFSC Code</th>
                                            <th scope="col">Entry Type</th>
                                            <th scope="col">Modify</th>
                                        </tr>
                                    </thead>
                                    {beneficiartList && beneficiartList.length > 0 && (
                                        <tbody>
                                            {beneficiartList.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <th className="align-middle">
                                                            <p>{i+1}</p>
                                                        </th>
                                                        <td className="align-middle">
                                                            <p>{item.account_name}</p>
                                                        </td>
                                                        <td className="align-middle">
                                                            <p>{item.bank_name}</p>
                                                        </td>
                                                        <td className="align-middle">
                                                            <p>{item.account_number}</p>
                                                        </td>
                                                        <td className="align-middle">
                                                            <p>{item.ifsc_code}</p>
                                                        </td>
                                                        <td className="align-middle">
                                                            <p>{item.entry_type}</p>
                                                        </td>
                                                       
                                                        <td className="align-middle">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => editBeneficiary(item)}
                                                            >
                                                                Edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    )}
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Beneficiaries;
