import React, { Component, useRef, useState } from "react";
// import { Grid, Card, Icon, Image, Button } from 'semantic-ui-react'
import "./dashboard.css";
import Card from "./card/card";
import Charts from "./chart/chart";
import ECharts from "./chart/echart";
import InfoCard from "./card/infocard";
import PlanCard from "./card/plancard";
import moment from 'moment-timezone';
import CIcon from "@coreui/icons-react";
import { cilArrowBottom, cilArrowTop, cilOptions } from "@coreui/icons";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";
import {
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CWidgetStatsA,
} from "@coreui/react";
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core";

import { FaCircle } from "react-icons/fa";
import Avatar1 from "../../assets/dashboard/images/user/avatar-1.jpg";
import Avatar2 from "../../assets/dashboard/images/user/avatar-2.jpg";
import Avatar3 from "../../assets/dashboard/images/user/avatar-3.jpg";
import Avatar4 from "../../assets/dashboard/images/user/avatar-4.jpg";
import Avatar5 from "../../assets/dashboard/images/user/avatar-5.jpg";
import circle from "../../assets/dashboard/circle.svg";

const PDashboard = () => {
    const [dashboardData, setDashboardData] = useState({});
    const [updateBanalceRequestList, setUpdateBanalceRequestList] = useState([]);

    const getDateFormat = (date) => {
        // console.log(date);
        // // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
        moment.tz.setDefault('"Asia/Calcutta"');
        let currentDate = moment(date).format('DD MMM YY, hh:mm a');
        return currentDate;
    };
  return (
    <div className="container pt-2">
      <div className="formcontainer" id="transfer">
       <div class="row mb-4">
              <div class="col-xl-9 col-md-6">
                  <div class="row mb-4">
                      <div class="card bg-primary custom-card card-box">
                          <div class="card-body p-4">
                              <div class="row align-items-center">
                                  <div class="offset-xl-4 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg ">
                                      <h4 class="d-flex  mb-3">
                                          <span class="font-weight-bold text-white ">Welcome To EazyPey!</span>
                                      </h4>
                                      <p class="text-white mb-1 f-w-600 f-15">Thank you for choosing us, Enjoy our services and
                                          Keep going to your level
                                      </p>
                                  </div>
                                  <img alt="" width="" class="" src="https://code.eazypey.com/webroot/images/work3.png" />

                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-warning text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-briefcase.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-warning">Recharge</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-warning">₹ {dashboardData.total?.recharge?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>
                          <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                              <mat-card class="mat-mdc-card mdc-card bg-light-accent text-center">
                                  <mat-card-content class="mat-mdc-card-content p-32">
                                      <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-connect.svg" />
                                      <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-accent">Reports</h4>
                                      <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-accent">59</h6>
                                  </mat-card-content>
                              </mat-card>
                          </div>
                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-success text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-speech-bubble.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-success">Pay In</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-success">₹ {dashboardData.total?.payIn?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>
                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-error text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-favorites.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-error">Pay Out</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-error">₹ {dashboardData.total?.payout?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>


                  </div>

                  <div class="ncard Recent-Users">
                      <div class="ncard-header">
                          <h5>Recent Balance requests</h5>
                      </div>
                      <div class="ncard-block px-3 py-3">
                          <div class="table-responsive">
                              <table class="ntable ntable-hover thead-light">
                                  <tbody>
                                      {
                                          updateBanalceRequestList.map((item, i) => {
                                              return (
                                                  <tr className="unread">
                                                      <td>
                                                          <img
                                                              class="rounded-circle"
                                                              style={{ width: "40px" }}
                                                              src={Avatar2}
                                                              alt="activity-user"
                                                          />
                                                      </td>
                                                      <td>
                                                          <h6 class="mb-1">{item.userName}</h6>
                                                          <p class="m-0">{item.userPhone}</p>
                                                      </td>
                                                      <td>
                                                          <p class="m-0">{item.comments}</p>
                                                      </td>
                                                      <td>
                                                          <h6 class="mb-1">₹ {item.amount}</h6>
                                                      </td>
                                                      <td>
                                                          <h6 class="text-muted">
                                                              {
                                                                  item.status == "PROCESSED"
                                                                      ? <FaCircle class="text-c-green f-10 m-r-15" />
                                                                      : item.status == "REJECTED"
                                                                          ? <FaCircle class="text-c-red f-10 m-r-15" />
                                                                          : <FaCircle class="text-c-purple f-10 m-r-15" />
                                                              }
                                                              {getDateFormat(item.created_at)}
                                                          </h6>
                                                      </td>
                                                  </tr>
                                              )
                                          })
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>

              </div>
              <div class="col-xl-3 col-md-6">
                  <div class="ncard daily-sales">
                      <div class="ncard-block">
                          <h6 class="mb-3 dhead">Daily Sales</h6>
                          <div class="row d-flex align-items-center">
                              <div class="col-9">
                                  <h3 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                                      <i class="feather icon-arrow-up text-c-green f-15 m-r-10"></i>
                                      ₹ {dashboardData.today?.all?.total ?? "0"}
                                  </h3>
                              </div>

                              <div class="col-3 text-right">
                                  {/* <p class="m-b-0">67%</p> */}
                              </div>
                          </div>
                          <div class="progress m-t-20" style={{ height: "7px" }}>
                              <div
                                  class="progress-bar progress-c-theme"
                                  role="progressbar"
                                  style={{ width: "50%" }}
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                              ></div>
                          </div>
                      </div>
                  </div>


                  <div class="ncard Monthly-sales">
                      <div class="ncard-block">
                          <h6 class="mb-3 dhead">Yesterday Sales</h6>
                          <div class="row d-flex align-items-center">
                              <div class="col-9">
                                  <h3 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                                      <i class="feather icon-arrow-down text-c-red f-15 m-r-10"></i>
                                      ₹ {dashboardData.yesterday?.all?.total ?? "0"}
                                  </h3>
                              </div>
                              <div class="col-3 text-right">
                                  {/* <p class="m-b-0">36%</p> */}
                              </div>
                          </div>
                          <div class="progress m-t-20" style={{ height: "7px" }}>
                              <div
                                  class="progress-bar progress-c-theme2"
                                  role="progressbar"
                                  style={{ width: "35%" }}
                                  aria-valuenow="35"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                              ></div>
                          </div>
                      </div>
                  </div>


                  <div class="ncard yearly-sales">
                      <div class="ncard-block">
                          <h6 class="mb-3 dhead">Monthly Sales</h6>
                          <div class="row d-flex align-items-center">
                              <div class="col-9">
                                  <h3 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                                      <i class="feather icon-arrow-up text-c-green f-15 m-r-10"></i>
                                      ₹ {dashboardData.lastMonth?.all?.total ?? "0"}
                                  </h3>
                              </div>
                              <div class="col-3 text-right">
                                  {/* <p class="m-b-0">80%</p> */}
                              </div>
                          </div>
                          <div class="progress m-t-20" style={{ height: "7px" }}>
                              <div
                                  class="progress-bar progress-c-theme"
                                  role="progressbar"
                                  style={{ width: "70%" }}
                                  aria-valuenow="70"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                              ></div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </div>
      </div>
   
  );
};

export default PDashboard;
