import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const rDashBoardDetailsRequest = (data) => async () => {
    try {
        let result = await ServiceCalls.get(
            `${API_URL.R_DASHBOARD_DATA}${data.id}`,
            null,
            true
        );
        return result;
    } catch (error) { }
};


export const walletBalanceDetailsRequest = (data) => async () => {
    try {
        let result = await ServiceCalls.get(
            `${API_URL.R_WALLET_BALANCE}${data.id}`,
            null,
            true
        );
        return result;
    } catch (error) { }
};

export const adminDashBoardDetailsRequest = (data) => async () => {
    try {
        let result = await ServiceCalls.get(
            `${API_URL.A_DASHBOARD_DATA}`,
            null,
            true
        );
        return result;
    } catch (error) { }
};