import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./login.css";
import AuthService from "../../utils/services/auth-service";
import { Toast } from "react-bootstrap";
import { BounceLoader } from "react-spinners";
import { isString } from "antd/es/button";

const Login = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [isStoring, setIsStoring] = useState(false);

  const [loginForm, setLoginForm] = useState({
    userName: "",
    password: "",
  });

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const login = async () => {
    await localStorage.setItem("authToken", "");
    setIsStoring(true);
    let login = await AuthService.login(loginForm.userName, loginForm.password);
    setIsStoring(false);
    if (login == true) {
      navigate("/dashboard");
    } else {
      setShowMessage("Invalid username or Password");
      setShow(true);
    }
  };

  return (
    <>
      {isStoring && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="loaderParentDiv">
            <div className="loaderDivCenter">
              <BounceLoader color="#36d7b7" loading={isStoring} size={200} />
            </div>
          </div>
        </div>
      )}{" "}
      <section
        className=""
        style={{  }}
      >
        <div
          class=""
        >
          <div class="container px-md-5 text-lg-start my-5 align-items-center">
            <div
              class="row gx-lg-5 align-items-center mb-5"
            >
                          <div class="col-lg-6 mb-5 mb-lg-0 dnd" style={{ zIndex: 10 }}>
                              <img
                                  style={{
                                      height: 60,
                                  }}
                                  className="logo"

                                  src="https://code.eazypey.com/webroot/images/logo/logo.png"



                              />
                <h1
                  class="my-5 display-5 fw-bold ls-tight"
                  style={{ color: "hsl(218, 81%, 95%)" }}
                >
                  Online Quick Recharge. <br />
                  <span style={{ color: "hsl(218, 81%, 75%)" }}>
                    Save Time and Money!
                  </span>
                </h1>
                <p
                  class="mb-4 opacity-70"
                  style={{ color: "hsl(218, 81%, 85%)" }}
                >
                  The simple, intuitive and powerful application to manage your
                  work.
                </p>
              </div>

              <div class="col-lg-6 mb-5 mb-lg-0 position-relative">
                <div
                  id="radius-shape-1"
                  class="position-absolute rounded-circle shadow-5-strong"
                ></div>
                <div
                  id="radius-shape-2"
                  class="position-absolute shadow-5-strong"
                ></div>

                <div class="card bg-glass">
                                  <div class="card-body px-md-5">
                                      <img
                                          style={{
                                              width: 142, marginBottom: 18, 
                                          }}
                                          className="mble-dsply"

                                          src="https://code.eazypey.com/webroot/images/logo/logo3.png"



                                      />
                    <div className="text-center">
                                          <p className="lgn-h4">LOGIN</p>
                    </div>

                                      <p className="lgn-p">
                      A few more clicks to sign in to your account. Manage all
                      your accounts in one place
                    </p>
                    <br />
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form2Example11">
                        Username
                      </label>
                      <input
                        type="email"
                        name="userName"
                        value={loginForm.userName}
                        onChange={(e) => handleChanges(e)}
                        id="form2Example11"
                        className="form-control"
                        placeholder="Phone number or email address"
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form2Example22">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={loginForm.password}
                        onChange={(e) => handleChanges(e)}
                        id="form2Example22"
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>

                    <div className="text-center pt-1 mb-5 pb-1">
                      <button
                        className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3"
                        onClick={(e) => login(e)}
                      >
                        Log in
                      </button>
                      {/* <a className="text-muted" href="#!">Forgot password?</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            right: 10,
          }}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header className="table" closeButton={false}>
              <strong className="mr-auto">Alert !!</strong>
            </Toast.Header>
            <Toast.Body className="table">{showMessage}</Toast.Body>
          </Toast>
        </div>
      </section>
    </>
  );
};

export default Login;
