import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordReq } from "../store/actions/onUserDataResultAction";
import AlertView from "./alert-view"
import RingLoadingHud from "./loadinghud";

const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(props.parentToChild);
  const [changePasswordForm, setChangePasswordForm] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isStoring, setIsStoring] = useState(false);

  const handleTranferChanges = (e) => {
    console.log("Debug > ", e);
    setChangePasswordForm({
      ...changePasswordForm,
      [e.target.name]: e.target.value,
    });
  };

  const changePassword = async () => {
    if (changePasswordForm.old_password.length === 0) {

      console.log(localStorage.getItem("userId"));
      showAlert('Please enter old password')

    } else if (changePasswordForm.new_password.length === 0) {
      showAlert('Please enter new password')
    } else if (changePasswordForm.confirm_password.length === 0) {
      showAlert('Please enter conform password')
    } else if (changePasswordForm.new_password != changePasswordForm.confirm_password) {
      showAlert('Please enter new password and conform passwords are same.')
    } else {
      setIsStoring(true);
      let requestBody = {
        "userId": localStorage.getItem("userId"),
        "password": changePasswordForm.old_password,
        "cnfrmPassword": changePasswordForm.new_password
      }

      await dispatch(updatePasswordReq(requestBody)).then(
        (response) => {
          setIsStoring(false);
          console.log(response);
          if (response.status == true) {
            showAlert(response.message)
            if (props.onClose) props.onClose();
          } else {
            showAlert(response.result)
          }
        }
      );
    }


  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      {
        isAlertView && (
          <AlertView
            message={alertMessage}
            onClose={() => {
              setIsAlertView(false);
            }}
          />
        )
      }
      <div>
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-10 card-1 p-5">
              <h3 className="text-center">Change Password</h3>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Enter old password</label>
                  <input
                    type="password"
                    name="old_password"
                    className="form-control"
                    value={changePasswordForm.old_password}
                    onChange={(e) => handleTranferChanges(e)}
                  />
                  <label>Enter new password</label>
                  <input
                    type="password"
                    name="new_password"
                    className="form-control"
                    value={changePasswordForm.new_password}
                    onChange={(e) => handleTranferChanges(e)}
                  />
                  <label>Confirm password</label>
                  <input
                    type="password"
                    name="confirm_password"
                    className="form-control"
                    value={changePasswordForm.confirm_password}
                    onChange={(e) => handleTranferChanges(e)}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{ display: "flex", justifyContent: "center" }}
                      type="button"
                      className="btn btn-purple-bg"
                      onClick={() => changePassword()}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-white-bg"
                      onClick={() => {
                        if (props.onClose) props.onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
