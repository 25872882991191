import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const vendorWalletChackUserRequestSession = (data) => async () => {
    try {
        let result = await ServiceCalls.post(
            `${API_URL.VENDOR_WALLET_CHECK_USER}`,
            data,
            true
        );
        return result;
    } catch (error) { }
};

export const vendorWalletUpdateRequestSession = (data) => async () => {
    try {
        let result = await ServiceCalls.post(
            `${API_URL.VENDOR_WALLET_UPDATE}`,
            data,
            true
        );
        return result;
    } catch (error) { }
};