import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";
import {
  getV_Balance,
  getV_Balance_ByEntryRequest,
} from "../../../store/actions/onvBalanceDataResultAction";
import { v_balanceAPI_ByEntry } from "../../../api/vBalanceApi";
import RingLoadingHud from "../../../components/loadinghud";

const VendorWalletTransactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wTransactionsForm, setWTransactionsForm] = useState({});
  const [virtualBalance, setVirtualBalance] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const handleTranferChanges = (e) => { };
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    getWalletTransactions(10, 0);

    console.log("v b page");
  }, []);

  const getWalletTransactions = async (limit, offset) => {
    setIsStoring(true);
    await dispatch(
      getV_Balance_ByEntryRequest(
        {
          limit: limit,
          offset: offset,
          type: "Debit",
          entry: "WALLET",
          vId: "",
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        setVirtualBalance(response.result);
      }
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    getWalletTransactions(10, (pageNumber*10)-10);
  };

  const validatePagination = () => {
    if(activePage <= 1) {
      if(virtualBalance.length > 9) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  return (
    <>
    {isStoring && (
        <RingLoadingHud />
      )}
      <div className="container pt-2">
        <div className="row justify-content-around ">
                  <div class="tble">
          <p>Wallet Transactions</p>
            <h2 className="pageHeading">Wallet</h2>
          </div>
          <div className="row m-3">
                      <div className="col-md-12 card-1 ovrscrl">
              <table className="table table-hover align-middle">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">S. No.</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Added By</th>
                    <th scope="col">Vendor</th>
                    <th scope="col">Comments</th>
                    <th scope="col">Type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                {virtualBalance && virtualBalance.length > 0 && (
                  <tbody>
                    {virtualBalance.map((item, i) => {
                      return (
                        <tr key={i}>
                          {/* <th scope="row">{od.id}</th> */}
                          <th className="align-middle">
                          <p>{i+1+((activePage*10) - 10)}</p>
                          </th>
                          <td className="align-middle">
                            <p>
                              {item.amount}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.adminName}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.vendorName}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.comments}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.type}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p>
                              {item.status}
                              {/* {getDateFormat(od.created_at)} */}
                            </p>
                          </td>
                          <td className="align-middle">
                            <p class="warning-badge">
                              {getDateFormat(item.created_at)}
                            </p>
                          </td>
                          {/* <td className="align-middle">
                              <button
                                type="button"
                                className="btn btn-purple-bg"
                                onClick={() => {
                                  navigate(`/order-detail?orderId=${od.id}`);
                                }}
                              >
                                <AiFillEye />
                              </button>
                            </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {
                validatePagination()
                  ? <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={50}
                    pageRangeDisplayed={5}
                    onChange={(number) => handlePageChange(number)}
                  />
                  : <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorWalletTransactions;
