import React, { Component, useRef, useState } from "react";
// import { Grid, Card, Icon, Image, Button } from 'semantic-ui-react'
import "./dashboard.css";
import Card from "./card/card";
import Charts from "./chart/chart";
import ECharts from "./chart/echart";
import InfoCard from "./card/infocard";
import PlanCard from "./card/plancard";
import CIcon from "@coreui/icons-react";
import { cilArrowBottom, cilArrowTop, cilOptions } from "@coreui/icons";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";
import {
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
  CWidgetStatsA,
} from "@coreui/react";
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core";
import RDashboard from "./rdashboard";
import AdminDashboard from "./AdminDashboard";
import PDashboard from "./pdashboard";
import DDashboard from "./ddashboard";

const Dashboard = () => {
  const roleId = localStorage.getItem("user_role_id");
  if (roleId === "4") {
    return <RDashboard />;
  } else if (roleId === "3") {
    return <DDashboard />;
  } else if (roleId === "2") {
    return <PDashboard />;
  } else {
    return <AdminDashboard />;
  }
};

export default Dashboard;
