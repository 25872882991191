import React, { useEffect, useRef, useState } from "react";
import {
  vendorWalletChackUserRequest,
  vendorWalletUpdate,
} from "../vendorWalletAPIActions";
import { useDispatch } from "react-redux";
import { CircleLoader } from "react-spinners";
import { Toast } from "react-bootstrap";
import { getV_Balance_ByEntryRequest, getVirtualWalletListRequest, updateWalletRequestAmountRequest } from "../../../store/actions/onvBalanceDataResultAction";
import AlertView from "../../../components/alert-view";
import RingLoadingHud from "../../../components/loadinghud";

const WalletRequestPage = () => {
  const dispatch = useDispatch();
  const [addwalletForm, setAddwalletFormForm] = useState({
    username: "",
  });

  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [addAmountWalletForm, setAddAmountWalletForm] = useState({
    walletAmount: "",
    comments: "",
  });
  const [virtualBalance, setVirtualBalance] = useState([]);
  const handleUserChanges = (e) => {
    setAddwalletFormForm({ ...addwalletForm, username: e.target.value });
  };
  const [isStoring, setIsStoring] = useState(false);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    getWalletTransactions(10, 0, localStorage.getItem("userId"));
    return () => { };
  }, []);

  const handleAddWalletChanges = (e) => {
    setAddAmountWalletForm({
      ...addAmountWalletForm,
      [e.target.name]: e.target.value,
    });
  };

  const getWalletTransactions = async (limit, offset, vId) => {
    setIsStoring(true);
    await dispatch(
      getVirtualWalletListRequest(
        {
          limit: limit,
          offset: offset,
          vId: vId,
        },
        dispatch
      )
    ).then((response) => {
      setIsStoring(false);
      if (response.result) {
        console.log(response.result);
        setVirtualBalance(response.result);
      } else {
        setVirtualBalance([]);
        showAlert(response.message);
      }
    });
  };

  const getDateFormat = (date) => {
    console.log(date);
    // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    return date;
  };

  const addFundsToWallet = async () => {
    if (addAmountWalletForm.walletAmount.length === 0) {
      setShowMessage("please enter amount");
      setShow(true);
      // alert("please enter amount");
    } else if (addAmountWalletForm.comments.length === 0) {
      setShowMessage("please enter comments");
      setShow(true);
      // alert("please enter comments");
    } else {
      setIsStoring(true);
      await dispatch(
        updateWalletRequestAmountRequest(
          {
            vendorId: localStorage.getItem("userId"),
            amount: addAmountWalletForm.walletAmount,
            comments: addAmountWalletForm.comments,
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          let emptyvals = {
            walletAmount: "",
            comments: "",
          };
          setAddAmountWalletForm(emptyvals);
          setShowMessage("User wallet updated.");
          setShow(true);
          getWalletTransactions(5, 0, localStorage.getItem("userId"));
        } else {
          setShowMessage(response.message);
          setShow(true);
        }
      });
    }
  };


  const showAlert = (message) => {
    console.log(message);
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && <RingLoadingHud />}
      {isAlertView && (
        <AlertView
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      <div className="container pt-2">
        <div className="row justify-content-around">
          
                  <div className="formcontainer" id="transfer">
                      <div class="tble">
                          <p className="">Wallet Request</p>
                      </div>
            <div class="row m-3 ">
              <div class="col-md-8 mb-3">
                              <div className="col-md-12 card-1 ovrscrl">
                  <table className="table table-hover align-middle">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {virtualBalance.map((item, i) => {
                        return (
                          <tr key={i}>
                            {/* <th scope="row">{od.id}</th> */}
                            <th className="align-middle">
                              <p>{i + 1}</p>
                            </th>
                            <td className="align-middle">
                              <p>
                                ₹ {item.amount}
                                {/* {getDateFormat(od.created_at)} */}
                              </p>
                            </td>
                            <td className="align-middle">
                              <p>
                                {item.comments}
                                {/* {getDateFormat(od.created_at)} */}
                              </p>
                            </td>
                            <td className="align-middle">
                              <p>
                                {item.status == "PROCESSED"
                                  ? <div className="status-mark-success"></div>
                                  : item.status == "PENDING"
                                    ? <div className="status-mark-pending"></div>
                                    : <div className="status-mark-failure"></div>}

                              </p>
                            </td>
                            <td className="align-middle">
                              <p class="warning-badge">
                                {getDateFormat(item.created_at)}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-4">

                              <div className="formcontainer ncard" id="transfer">
                  <div className="col-auto">
                    <div className="row justify-content-around">
                      <h6 className="ncard-header">
                        Recharge Wallet
                      </h6>
                      <div className="px-4 py-4">
                        <label>Wallet Amount</label>
                        <input
                          type="number"
                          name="walletAmount"
                          className="form-control"
                          value={addwalletForm.walletAmount}
                          onChange={(e) => handleAddWalletChanges(e)}
                        />
                        <label>Comments</label>
                        <input
                          type="text"
                          name="comments"
                          className="form-control"
                          value={addwalletForm.comments}
                          onChange={(e) => handleAddWalletChanges(e)}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => addFundsToWallet()}
                          >
                            Request Amount
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 60,
            right: 10,
          }}
        >
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Header className="table" closeButton={false}>
              <strong className="mr-auto">info</strong>
            </Toast.Header>
            <Toast.Body className="table">{showMessage}</Toast.Body>
          </Toast>
        </div>
      </div>
    </>
  );
};

export default WalletRequestPage;
