import React, { Component, useRef, useState } from "react";
import RBillPaymentReports from "./rbillpaymentreports";
import PBillPaymentReports from "./pbillpaymentreports";
import AdminBillpaymentReports from "./AdminBillpaymentReports";
import DBillPaymentReports from "./dbillpaymentreports";

const BillPaymentReports = () => {
  const roleId = localStorage.getItem("user_role_id");
  if (roleId === "4") {
    return <RBillPaymentReports />;
  } else if (roleId === "3") {
    return <DBillPaymentReports />;
  } else if (roleId === "2") {
    return <PBillPaymentReports />;
  } else {
    return <AdminBillpaymentReports />;
  }
};

export default BillPaymentReports;
