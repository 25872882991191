export const API_URL = {
  DATA_LIST: "dataListApi",
  POST_LIST: "dataPostApi",
  LOGIN: "Auth/login",
  USER_LIST: "User/listUsers",
  VIRTUAL_BALANCE: "VirtualBalance/list",
  VIRTUAL_BALANCE_LIST: "VendorWallet/walletRequestList",
  VIRTUAL_BALANCE_STATUS: "VendorWallet/updateWalletRequest",
  VIRTUAL_BALANCE_TOTAL: "VirtualBalance/balance",
  UPDATE_BALANCE: "VirtualBalance/updateBalance",
  UPDATE_WALLETBALANCE_REQUEST: "VendorWallet/request",
  UPDATE_PAYOUT: "https://api.eazypey.com/eko/initiatePayment.php",
  PAYOUT_INITIATE_PAYMENT: "https://api.eazypey.com/eko/initiatePayment.php",
  UPDATE_PAN: "eko/verifyPan.php",
  // UPDATE_PAYOUT: "https://demo.eazypey.com/eko/initiatePayment.php",
  ADD_USER: "Auth/addUser",
  UPDATE_USER: "User/update",
  UPDATE_USER_PWD: "User/updatePassword",
  EKO_GET_MOBILE_PREPAID_OPERATORS: "eko/getOperators.php?categoryId=",
  EKO_GET_DTH_OPERATORS: "eko/getOperators.php?categoryId=4",
  EKO_PAY_BILL: "eko/payBill.php",
  USER_INFO: "User/info?id=",

  ZPAY_REVHARGE_PROVIDERS: "ZuelPayUtil/getRechargeProvders?type=",
  ZPAY_CIRCLE_CODES: "ZuelPayUtil/getCircleCodes",
  ZPAY_REHARGE: "ZuelPayUtil/doRecharge",
  ZPAY_RC_PLANS: "ZuelPayUtil/plans",

  ZPAY_BBPS_OPTIONS: "ZuelPayUtil/getBbpsOptions",
  ZPAY_BBPS_PROVIDERS: "ZuelPayUtil/getBbpsProviders?type=",
  ZPAY_BBPS_FETCH_BILL: "ZuelPayUtil/fetchBill",
  ZPAY_BBPS_PAY_BILL: "ZuelPayUtil/payBill",
  VENDOR_WALLET_CHECK_USER: "Auth/checkUser",
  VENDOR_WALLET_UPDATE: "VendorWallet/updateVendorWallet",

  BENEFICIARY_LIST: "Beneficiary/list?userId=",
  BENEFICIARY_ADD: "Beneficiary/Update",

  WALLET_BALANCE: "VendorWallet/getBalance",

  SLAB_LIST: "Slab/list",
  SLAB_ADD_UPDATE: "Slab/update",

  REPORTS: "Report/list",
  REPORTS_UPDATE: "Report/update",
  R_DASHBOARD_DATA: "Report/retailerDashBoard?loginId=",
  A_DASHBOARD_DATA: "Report/adminDashBoard",
  R_WALLET_BALANCE: "VendorWallet/getWalletBalance?vendorId=",
  BENEFICIARY_USER_LIST: "Beneficiary/usersList?addedBy=",
  SLAB_BASE_LIST: "SlabBase/list",
  SLAB_BASE_UPDATE: "SlabBase/update",
};

export const BASE_URL = {
  BASE_URL: "https://api.eazypey.com/", //process.env.REACT_APP_API_URL
  SECRET_KEY: "A31AB78E-C4C7-4C9E-AD98-6D6A1B801E45",
  // AUTH_BASE_URL: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
};
