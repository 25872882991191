import axios from "axios";
import { BASE_URL, API_URL } from "../ApiUrl";

const loginUrl = BASE_URL.BASE_URL + API_URL.LOGIN;

class AuthService {
  async login(username, password) {
    let data = {
      username: username,
      password: password,
      //  role: 3,
    };

    const post_data = data;
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "api-access-key": "A31AB78E-C4C7-4C9E-AD98-6D6A1B801E45",
      },
    };
    return axios
      .post(loginUrl, post_data, config)
      .then(async (response) => {
        // console.log("SDebug > ", response);

        // if (response.status === 401 || response.status.status === 400) {
        //   alert(response.data.message);
        // } else
        if (
          response.data &&
          response.data.result &&
          response.data.result.token
        ) {
          await localStorage.setItem("authToken", response.data.result.token);
          await localStorage.setItem("userId", response.data.result.id);
          await localStorage.setItem(
            "user_role_id",
            response.data.result.role_id
          );
          await localStorage.setItem("userName", response.data.result.name);
          await localStorage.setItem("userEmail", response.data.result.email);
          await localStorage.setItem("userPhone", response.data.result.phone);
          await localStorage.setItem(
            "superuserId",
            response.data.result.super_user_id
          );
        }
        return true;
      })
      .catch((error) => {
        // alert(error.response.data.message);
        return false;
      });
  }

  logout() {
    localStorage.removeItem("authToken");
  }

  isAuthenticated() {
    if (localStorage.getItem("authToken")) {
      return true;
    } else {
      return false;
    }
  }

  checkUserAccess(roles) {
    if (
      roles &&
      roles.length > 0 &&
      roles.includes(localStorage.getItem("user_role_id"))
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("authToken"));
  }
}

export default new AuthService();
