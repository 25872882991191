import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddSlabRequest } from "../store/actions/onUserDataResultAction";
import { Toast } from "react-bootstrap";
import { RingLoader } from "react-spinners";
import RingLoadingHudPopUp from "./loadinghudpopup";

const AddSlab = (props) => {

  const dispatch = useDispatch();
  const [providers, setProviders] = useState([
    { id: "1", name: "EKO" },
    { id: "2", name: "WORLDLINE" },
    { id: "3", name: "ZPAY" },
  ]);
  const [providerOptions, setProviderOptions] = useState([
    { id: "1", name: "PAYIN" },
    { id: "2", name: "PAYOUT" },
    { id: "3", name: "BBPS" },
    { id: "4", name: "RECHARGE" },
  ]);
  const [addUserForm, setAddUserForm] = useState({
    provider: "EKO",
    providerOption: "PAYIN",
    baseshare: "",
    name: "",
    owner: "",
    partner: "",
    distributor: "",
    retailer: "",
  });

  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [slabData, setSlabData] = useState(props.slabDetails);
  const [isStoring, setIsStoring] = useState(false);

  const handleTranferChanges = (e) => {
    console.log("Debug > ", e);
    setAddUserForm({ ...addUserForm, [e.target.name]: e.target.value });
  };

  const addNewBeneficiary = async () => {
    let baseShare = parseFloat(addUserForm.baseshare);
    console.log("baseShare ", baseShare);
    if (addUserForm.provider.length === 0) {
      setShowMessage("Please select provider");
      setShow(true);
    } else if (addUserForm.baseshare.length === 0) {
      setShowMessage("Please enter base share");
      setShow(true);
    } else if (baseShare < 0 || 100 <= baseShare) {
      setShowMessage("Please enter base share in between 0 to 100.");
      setShow(true);
    } else if (addUserForm.name.length === 0) {
      setShowMessage("Enter slab name.");
      setShow(true);
    } else if (addUserForm.owner.length === 0) {
      setShowMessage("Enter Admin %.");
      setShow(true);
    } else if (addUserForm.partner.length === 0) {
      setShowMessage("Enter Partner %.");
      setShow(true);
    } else if (addUserForm.distributor.length === 0) {
      setShowMessage("Enter distributor %.");
      setShow(true);
    } else if (addUserForm.retailer.length === 0) {
      setShowMessage("Enter retailer %.");
      setShow(true);
    } else if (
      parseInt(addUserForm.retailer) +
        parseInt(addUserForm.distributor) +
        parseInt(addUserForm.partner) +
        parseInt(addUserForm.owner) >
      100
    ) {
      setShowMessage("Total Percent exceeded than 100.");
      setShow(true);
    } else if (
      parseInt(addUserForm.retailer) +
        parseInt(addUserForm.distributor) +
        parseInt(addUserForm.partner) +
        parseInt(addUserForm.owner) <
      100
    ) {
      setShowMessage("Total Percent less than 100.");
      setShow(true);
    } else {
      console.log("Initiate transfer");
      setIsStoring(true);
      await dispatch(
        AddSlabRequest(
          {
            provider: addUserForm.provider,
            baseShare: addUserForm.baseshare,
            providerOption: addUserForm.providerOption,
            name: addUserForm.name,
            owner: addUserForm.owner,
            partner: addUserForm.partner,
            distributor: addUserForm.distributor,
            retailer: addUserForm.retailer,
            slabId: slabData.id
          },
          dispatch
        )
      ).then((response) => {
        setIsStoring(false);
        if (response) {
          let emptyvals = {
            name: "",
            owner: "",
            partner: "",
            distributor: "",
            retailer: "",
            baseshare: "",
            provider: "",
          };
          setAddUserForm(emptyvals);

          setShowMessage(response.message);
          setShow(true);
          props.onClose();
        }
      });
    }
  };

  return (
    <>
      {isStoring && <RingLoadingHudPopUp />}
      <div>
        <div className="container">
          <div className="row mt-2">
            <div className="col-12 card-1 px-4 py-2">
              <h3 className="text-center table">Add Slab</h3>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="row justify-content-left">
                    <div className="col">
                      <label>Provider</label>
                      <select
                        name="provider"
                        className="form-control"
                        onChange={(e) => handleTranferChanges(e)}
                      >
                        {providers.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col">
                      <label>ProviderOption</label>
                      <select
                        name="providerOption"
                        className="form-control"
                        onChange={(e) => handleTranferChanges(e)}
                      >
                        {providerOptions.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col">
                      <label>Base share</label>
                      {/* <select
                        name="providerOption"
                        className="form-control"
                        onChange={(e) => handleTranferChanges(e)}
                      >
                        {providerOptions.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select> */}
                      <input
                        type="number"
                        name="baseshare"
                        className="form-control"
                        value={addUserForm.baseshare}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-left">
                    <div className="col">
                      <label>Slab Title</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={addUserForm.name}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                    <div className="col">
                      <label>Admin %</label>
                      <input
                        type="number"
                        name="owner"
                        className="form-control"
                        value={addUserForm.owner}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-left">
                    <div className="col">
                      <label>Partner %</label>
                      <input
                        type="number"
                        name="partner"
                        className="form-control"
                        value={addUserForm.partner}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                    <div className="col">
                      <label>Distributor %</label>
                      <input
                        type="number"
                        name="distributor"
                        className="form-control"
                        value={addUserForm.confirm_account}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                  </div>
                  <div className="row justify-content-left">
                    <div className="col">
                      <label>Retailer %</label>
                      <input
                        type="number"
                        name="retailer"
                        className="form-control"
                        value={addUserForm.retailer}
                        onChange={(e) => handleTranferChanges(e)}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{ display: "flex", justifyContent: "center" }}
                      type="button"
                      className="btn btn-purple-bg"
                      onClick={() => addNewBeneficiary()}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-white-bg"
                      onClick={() => {
                        if (props.onClose) props.onClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: 60,
              right: 200,
            }}
          >
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
            >
              <Toast.Header className="table" closeButton={false}>
                <strong className="mr-auto">info</strong>
              </Toast.Header>
              <Toast.Body className="table">{showMessage}</Toast.Body>
            </Toast>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlab;
