import {
  addOrUpdateSlabBaseSession,
  addOrUpdateSlabSession,
  addUserRequest,
  getUserInfo,
  slabBaseListSession,
  slabListSession,
  updateUserPasswordRequest,
  updateUserRequest,
} from "../../api/userUpdateAPI";

const addUserReq = (data) => async (dispatch) => {
  const response = await dispatch(addUserRequest(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updateUserReq = (data) => async (dispatch) => {
  const response = await dispatch(updateUserRequest(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const updatePasswordReq = (data) => async (dispatch) => {
  const response = await dispatch(updateUserPasswordRequest(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const slabListRequest = (data) => async (dispatch) => {
  const response = await dispatch(slabListSession(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const AddSlabRequest = (data) => async (dispatch) => {
  const response = await dispatch(addOrUpdateSlabSession(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const slabBaseListRequest = (data) => async (dispatch) => {
  const response = await dispatch(slabBaseListSession(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const AddSlabBaseRequest = (data) => async (dispatch) => {
  const response = await dispatch(addOrUpdateSlabBaseSession(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

const getUserInfoReq = (data) => async (dispatch) => {
  const response = await dispatch(getUserInfo(data));
  if (
    (response &&
      Object.keys(response).length &&
      response?.data?.status == 201) ||
    200
  ) {
    return Promise.resolve(response.data);
  } else {
    return Promise.resolve(null);
  }
};

export {
  addUserReq,
  updateUserReq,
  slabListRequest,
  AddSlabRequest,
  updatePasswordReq,
  getUserInfoReq,
  slabBaseListRequest,
  AddSlabBaseRequest,
};
