import { API_URL } from "../utils/ApiUrl";
import ServiceCalls from "../utils/services/httpServiceCalls";

export const getReportsAPIService = (data) => async () => {
  try {
    let result = await ServiceCalls.get(
      `${API_URL.REPORTS}?entryType=${data.entryType}&limit=${data.limit}&offset=${data.offset}&status=${data.status}&userId=${data.userId}&page=${data.page}&tDate=${data.tDate}&startDate=${data.startDate}&endDate=${data.endDate}&loginId=${data.loginId}`,
      null,
      true
    );
    return result;
  } catch (error) {}
};

export const updateReportService = (data) => async () => {
  try {
    let result = await ServiceCalls.post(
      `${API_URL.REPORTS_UPDATE}`,
      data,
      true
    );
    return result;
  } catch (error) {}
};
