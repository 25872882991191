import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../../components/change-password";
import logo from "../../assets/logo.png";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  useEffect(() => {}, []);

  React.useEffect(() => {});

  return (
    <>
      {showChangePasswordForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-4">
              <ChangePassword
                onClose={() => {
                  console.log("Add User closed");
                  setShowChangePasswordForm(false);
                }}
                parentToChild={{ id: localStorage.getItem("userId") }}
              />
            </div>
          </div>
        </div>
      )}

      <div class="container">
        <div class="row justify-content-around">
          <p class="tble">My Account</p>
          <div class="row gutters-sm">
            <div class="col-md-5 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center">
                    <img src={logo} alt="Admin" width="150" />
                    <div class="m-4">
                      <h4> {localStorage.getItem("userName")}</h4>

                      <b class="text-muted font-size-sm m-2">
                        {localStorage.getItem("userEmail")} /{" "}
                        {localStorage.getItem("userPhone")}
                      </b>
                      {/* <button class="btn btn-primary">Follow</button> */}
                      <button
                        class="btn btn-primary m-2"
                        onClick={() => setShowChangePasswordForm(true)}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
