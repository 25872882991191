import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { veryfyPanRequest } from '../../recharge/actions/onBankingResultAction';
import { RingLoader } from "react-spinners";
import RingLoadingHud from "../../../components/loadinghud";

const VerifyPan = () => {

    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState({});
    const [panForm, setPanForm] = useState({ pannumber: "", });
    const [userDetailsFlag, setUserDetailsFlag] = useState(false);
    const [isStoring, setIsStoring] = useState(false);

    const handleChanges = (e) => {
        console.log("SDebug > ", e.target.value);
        setPanForm({ ...panForm, [e.target.name]: e.target.value });
    };


    const verifyPan_Number = async (panNumber) => {
        setIsStoring(true);
        await dispatch(
            veryfyPanRequest({ pan: panNumber }, dispatch)
        ).then((response) => {
            setIsStoring(false);
            if (response) {
                setUserDetails(response)
                setUserDetailsFlag(true);
                panForm.pannumber = ""
            }
        });
    };

    const verifyPanNumber = async () => {
        console.log(panForm.pannumber.length);
        if (panForm.pannumber.length == 10) {
            verifyPan_Number(panForm.pannumber);
        }
        else {
            alert("Invalid Pannumber");
        }
    };

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    return (
        <>
            {isStoring && (
                <RingLoadingHud />
            )}

            <div className="container pt-2">
                <div className="row justify-content-around m-5">
                    <div className="col-6">
                        <h2 className="pageHeading">Verify Pan number</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/dashboard">Home</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    pan_number
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row justify-content-around m-5">
                    <div className="formcontainer" id="transfer">
                        <div className="col-4">
                            <label>Pan number</label>
                            <input
                                type="text"
                                name="pannumber"
                                className="form-control"
                                value={panForm.pannumber}
                                onChange={(e) => handleChanges(e)}
                                placeholder="Enter pan number"
                                maxLength={10}
                                minLength={10}
                                onInput={toInputUppercase}
                            />
                            <button style={{
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}
                                type="button"
                                className="btn btn-purple-bg"
                                onClick={() => verifyPanNumber()}
                            >
                                Verify Pan Number
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-around m-5">
                    {
                        userDetailsFlag
                            ? (<div className="col-4">
                                <p>Name : <p>{userDetails.data.first_name} {userDetails.data.middle_name} {userDetails.data.last_name}</p></p>
                                <p>Pan number :<p>{userDetails.data.pan_number}</p></p>
                                <p>Message : <p>{userDetails.message}</p></p>
                            </div>)
                            : null
                    }
                </div>
            </div>
        </>
    );
};

export default VerifyPan;