import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getReportsRequest } from "../../../store/actions/onReportsResults";
import Pagination from "react-js-pagination";
import RingLoadingHud from "../../../components/loadinghud";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AlertView from "../../../components/alert-view";
import RPayoutReports from "./rpayoutreports";
import DPayoutReports from "./dpayoutreports";
import PPayoutReports from "./ppayoutreports";
import AdminPayoutReports from "./AdminPayoutreports";

const PayoutReports = () => {
  const roleId = localStorage.getItem("user_role_id");
  if (roleId === "4") {
    return <RPayoutReports />;
  } else if (roleId === "3") {
    return <DPayoutReports />;
  } else if (roleId === "2") {
    return <PPayoutReports />;
  } else {
    return <AdminPayoutReports />;
  }
};

export default PayoutReports;
