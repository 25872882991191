import React, { Component, useContext, useEffect, useRef, useState } from "react";
import "./dashboard.css";
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core";

import { FaCircle } from "react-icons/fa";

import Avatar2 from "../../assets/dashboard/images/user/avatar-2.jpg";
import circle from "../../assets/dashboard/circle.svg";
import { useDispatch } from "react-redux";
import { getUpdateVirtualWalletBalanceRequest, getVirtualWalletListRequest } from "../../store/actions/onvBalanceDataResultAction";
import moment from 'moment-timezone';
import RingLoadingHud from "../../components/loadinghud";
import AlertView from "../../components/alert-view";
import { getDashboardDetails, getVirtualWalletBalance } from "./dashboardrequest";
import { BalanceContext } from "../../components/walletprovider";
import { VirtualBalanceContext } from "../../components/virtualbalanceprovider";

const RDashboard = () => {
  const dispatch = useDispatch();

  const [updateBanalceRequestList, setUpdateBanalceRequestList] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [isStoring, setIsStoring] = useState(false);
  const [isAlertView, setIsAlertView] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const state = useContext(BalanceContext);
  const vState = useContext(VirtualBalanceContext);

  useEffect(() => {
    getWalletTransactionsList(5, 0); //API CALL
    getDashBoardData(); // API Call
    getRetaierWalletBalance(); // API CALL
    console.log("Admin dashboard");
  }, []);

  const getRetaierWalletBalance = async () => {
    await dispatch(
      getVirtualWalletBalance(
        {
          id: localStorage.getItem("userId")
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        state.setAmount(response.result.mainBalance);
        vState.setVirtualAmount(response.result.virtualBalance);

      } else {
        showAlert(response.message);
      }
    });
  }
  const getWalletTransactionsList = async (limit, offset) => {
    // setIsStoring(true);
    await dispatch(
      getVirtualWalletListRequest(
        {
          limit: limit,
          offset: offset,
          vId: localStorage.getItem("userId")
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        setUpdateBanalceRequestList(response.result);
      } else {
        setUpdateBanalceRequestList([]);
        // showAlert(response.message);
      }
    });
  };

  const getDashBoardData = async () => {
    // setIsStoring(true);
    await dispatch(
      getDashboardDetails(
        {
          id: localStorage.getItem("userId")
        },
        dispatch
      )
    ).then((response) => {
      // setIsStoring(false);
      if (response.result) {
        console.log(response)
        setDashboardData(response.result)
      } else {
        setDashboardData({});
        // showAlert(response.message);
      }
    });
  };

  const getDateFormat = (date) => {
    // console.log(date);
    // // console.log(moment(date).format('MMMM Do YYYY, h:mm:ss a'))
    moment.tz.setDefault('"Asia/Calcutta"');
    let currentDate = moment(date).format('DD MMM YY, hh:mm a');
    return currentDate;
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setIsAlertView(true);
  };

  return (
    <>
      {isStoring && (
        <RingLoadingHud />
      )}
      {isAlertView && (
        <AlertView style={{ width: 0 }}
          message={alertMessage}
          onClose={() => {
            setIsAlertView(false);
          }}
        />
      )}
      <div className="container pt-2">
        <div className="formcontainer" id="transfer">
         
                  <div class="row mb-4">
                      <div class="col-xl-9 col-md-6">
                          <div class="row mb-4">
                              <div class="card bg-primary custom-card card-box">
                                  <div class="card-body p-4">
                                      <div class="row align-items-center">
                                          <div class="offset-xl-4 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg ">
                                              <h4 class="d-flex  mb-3">
                                                  <span class="font-weight-bold text-white ">Welcome To EazyPey!</span>
                                              </h4>
                                              <p class="text-white mb-1 f-w-600 f-15">Thank you for choosing us, Enjoy our services and
                                                  Keep going to your level
                                              </p>
                                          </div>
                                          <img alt="" width="" class="" src="https://code.eazypey.com/webroot/images/work3.png" />

                                      </div>
                                  </div>
                              </div>
                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-warning text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-briefcase.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-warning">Recharge</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-warning">₹ {dashboardData.total?.recharge?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>
                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-accent text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-mailbox.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-accent">BBP</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-accent"> ₹ {dashboardData.total?.bbps?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>

                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-success text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-speech-bubble.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-success">Pay In</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-success">₹ {dashboardData.total?.payIn?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>
                      <div class="col-lg-3 col-sm-4 col-6 ng-star-inserted">
                          <mat-card class="mat-mdc-card mdc-card bg-light-error text-center">
                              <mat-card-content class="mat-mdc-card-content p-32">
                                  <img alt="users" width="40" class="rounded-circle" src="https://code.eazypey.com/webroot/images/svgs/icon-favorites.svg" />
                                  <h4 class="f-w-600 f-16 m-t-8 mat-subtitle-2 text-error">Pay Out</h4>
                                  <h6 class="f-w-600 m-t-4 m-t-8 mat-subtitle-1 f-20 text-error">₹ {dashboardData.total?.payout?.total ?? "0"}</h6>
                              </mat-card-content>
                          </mat-card>
                      </div>
            

                  </div>
                
              <div class="ncard Recent-Users">
                <div class="ncard-header">
                  <h5>Recent Balance requests</h5>
                </div>
                <div class="ncard-block px-3 py-3">
                  <div class="table-responsive">
                    <table class="ntable ntable-hover thead-light">
                      <tbody>
                        {
                          updateBanalceRequestList.map((item, i) => {
                            return (
                              <tr className="unread">
                                <td>
                                  <img
                                    class="rounded-circle"
                                    style={{ width: "40px" }}
                                    src={Avatar2}
                                    alt="activity-user"
                                  />
                                </td>
                                <td>
                                  <h6 class="mb-1">{item.userName}</h6>
                                  <p class="m-0">{item.userPhone}</p>
                                </td>
                                <td>
                                <p class="m-0">{item.comments}</p>
                                </td>
                                <td>
                                  <h6 class="mb-1">₹ {item.amount}</h6>
                                </td>
                                <td>
                                  <h6 class="text-muted">
                                    {
                                      item.status == "PROCESSED"
                                      ? <FaCircle class="text-c-green f-10 m-r-15" />
                                      : item.status == "REJECTED"
                                       ? <FaCircle class="text-c-red f-10 m-r-15" />
                                       : <FaCircle class="text-c-purple f-10 m-r-15" />
                                    }
                                    {getDateFormat(item.created_at)}
                                  </h6>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              </div>
           
            <div class="col-xl-3 col-md-6">
                              <div class="ncard daily-sales">
                                  <div class="ncard-block">
                                      <h6 class="mb-3 dhead">Daily Sales</h6>
                                      <div class="row d-flex align-items-center">
                                          <div class="col-9">
                                              <h3 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                                                  <i class="feather icon-arrow-up text-c-green f-15 m-r-10"></i>
                                                  ₹ {dashboardData.today?.all?.total ?? "0"}
                                              </h3>
                                          </div>

                                          <div class="col-3 text-right">
                                              {/* <p class="m-b-0">67%</p> */}
                                          </div>
                                      </div>
                                      <div class="progress m-t-20" style={{ height: "7px" }}>
                                          <div
                                              class="progress-bar progress-c-theme"
                                              role="progressbar"
                                              style={{ width: "50%" }}
                                              aria-valuenow="50"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                          ></div>
                                      </div>
                                  </div>
                              </div>
                         
                        
                              <div class="ncard Monthly-sales">
                                  <div class="ncard-block">
                                      <h6 class="mb-3 dhead">Yesterday Sales</h6>
                                      <div class="row d-flex align-items-center">
                                          <div class="col-9">
                                              <h3 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                                                  <i class="feather icon-arrow-down text-c-red f-15 m-r-10"></i>
                                                  ₹ {dashboardData.yesterday?.all?.total ?? "0"}
                                              </h3>
                                          </div>
                                          <div class="col-3 text-right">
                                              {/* <p class="m-b-0">36%</p> */}
                                          </div>
                                      </div>
                                      <div class="progress m-t-20" style={{ height: "7px" }}>
                                          <div
                                              class="progress-bar progress-c-theme2"
                                              role="progressbar"
                                              style={{ width: "35%" }}
                                              aria-valuenow="35"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                          ></div>
                                      </div>
                                  </div>
                              </div>
                         
                      
                              <div class="ncard yearly-sales">
                                  <div class="ncard-block">
                                      <h6 class="mb-3 dhead">Monthly Sales</h6>
                                      <div class="row d-flex align-items-center">
                                          <div class="col-9">
                                              <h3 class="f-w-600 d-flex align-items-center f-18 m-b-0">
                                                  <i class="feather icon-arrow-up text-c-green f-15 m-r-10"></i>
                                                  ₹ {dashboardData.lastMonth?.all?.total ?? "0"}
                                              </h3>
                                          </div>
                                          <div class="col-3 text-right">
                                              {/* <p class="m-b-0">80%</p> */}
                                          </div>
                                      </div>
                                      <div class="progress m-t-20" style={{ height: "7px" }}>
                                          <div
                                              class="progress-bar progress-c-theme"
                                              role="progressbar"
                                              style={{ width: "70%" }}
                                              aria-valuenow="70"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                          ></div>
                                      </div>
                                  </div>
                              </div>
                          
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default RDashboard;