import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getReportsRequest } from "../../../store/actions/onReportsResults";
import Pagination from "react-js-pagination";
import RingLoadingHud from "../../../components/loadinghud";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AlertView from "../../../components/alert-view";
import RPaymentLinks from "./rpaymentlinks";
import DPaymentLinks from "./dpaymentlinks";
import PPaymentLinks from "./ppaymentlinks";
import AdminPaymentLinks from "./AdminPaymentlinks";

const PaymentLinks = () => {
  const roleId = localStorage.getItem("user_role_id");
  console.log(roleId);
  if (roleId === "4") {
    console.log("H")
    return <RPaymentLinks />;
  } else if (roleId === "3") {
    console.log("He")
    return <DPaymentLinks />;
  } else if (roleId === "2") {
    console.log("Hel")
    return <PPaymentLinks />;
  } else {
    console.log("Hello")
    return <AdminPaymentLinks />;
  }
};

export default PaymentLinks;
